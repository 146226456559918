<template>
  <v-app class="background">
    <b-container class="mt-n8">
      <b-form>
        <b-card class="content">
          <b-card-title class="title">
            <b-row>
              <b-col>
                <h1 class="text-title">Tambahkan Dokter</h1>
              </b-col>
            </b-row>
          </b-card-title>

          <b-card-text>
            <p class="text-lable">Kategori</p>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-row>
                <b-col md="3" sm="6">
                  <b-form-radio
                    v-model="radios"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="GENERAL"
                    >Dokter Umum</b-form-radio
                  >
                </b-col>
                <b-col md="3" sm="6">
                  <b-form-radio
                    v-model="radios"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="spesialis"
                    >Dokter Spesialis</b-form-radio
                  >
                </b-col>
                <b-col md="3" sm="6">
                  <p>*)Wajib diisi</p>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="Nama Lengkap *">
                    <b-form-input
                      type="text"
                      v-model="user2.doctor.fullName"
                      placeholder="Masukkan Nama Lengkap"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Tanggal Lahir (YYYY-MM-DD)"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="user2.doctor.birthDate"
                          dense
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="user2.doctor.birthDate"
                        :max="today"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Biaya Konsultasi / 30 menit * "
                  >
                    <b-input-group
                      prepend="Rp"
                      label="Biaya Konsultasi / 30 menit * "
                    >
                      <b-form-input
                        id="biaya"
                        type="number"
                        v-model="user2.doctor.consultationFee"
                        placeholder
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

               
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Harga Coret"
                  >
                    <b-input-group
                      prepend="Rp"
                      label="Harga Coret"
                    >
                      <b-form-input
                        id="consultationSpecialFee"
                        type="number"
                        v-model="user2.doctor.consultationSpecialFee"
                        placeholder
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>


               

              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="Email *">
                    <v-text-field
                      placeholder="Enter email address"
                      dense
                      required
                      outlined
                      :rules="emailRules"
                      v-model="user2.doctor.email"
                    ></v-text-field>
                  </b-form-group>
                </b-col>
                 <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="No. SIP *">
                    <b-form-input
                      v-model="user2.doctor.sipNumber"
                      maxlength="60"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
               
              </b-row>
              <b-row>
               <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="No. Handphone *">
                    <b-form-input
                      v-model="user2.doctor.mobilePhone"
                      placeholder
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Hospital Employee Number *"
                  >
                    <b-form-input
                      v-model="user2.doctor.hospitalEmployeeNumber"
                      placeholder
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group class="text-label" label="Foto Dokter">
                    <b-form-file
                      @change="upload($event)"
                      accept="image/*"
                      placeholder="Upload foto dokter"
                      ref="fileinput"
                    ></b-form-file>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-card-text>

          <v-card v-if="radios === 'spesialis'">
            <v-row>
              <v-col cols="12" class="ml-4">
                <span
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Kategori</span
                >
              </v-col>
              <v-col cols="4" class="ml-4">
                <v-select
                  v-model="user2.doctor.docMstMedicSpecializationId"
                  :items="listspesialisasi"
                  item-value="value"
                  item-text="text"
                  outlined
                  dense
                  label="Spesialisasi Dokter *"
                  @input="selectdoctorspc(value)"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="listSubSpesialisasi"
                  v-model="user2.doctor.docMstMedicSubSpcId"
                  item-value="value"
                  item-text="text"
                  outlined
                  dense
                  label="Sub Spesialisasi Dokter"
                ></v-select>
              </v-col>
            </v-row>

            <!-- pengalaman -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Pengalaman</span
                >
              </v-col>
              <v-row>
                <v-col cols="10">
                  <v-row class="ml-2">
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        outlined
                        :items="years"
                        item-value="year"
                        v-model="experienceStartYear"
                      ></v-select>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        item-value="year"
                        outlined
                        v-model="experienceEndYear"
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        placeholder="Keterangan"
                        outlined
                        dense
                        v-model="experienceName"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="mt-4">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-1"
                    fab
                    dark
                    color="indigo"
                    x-small
                    @click="addPengalaman"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>

                <v-row
                  v-for="(pengalaman, i) in user2.doctor.experiences"
                  :key="i"
                  class="ml-2"
                >
                  <v-col cols="10" v-show="experienceShow"
                    ><v-row class="ml-2">
                      <v-col cols="2" class="ml-1">
                        <v-text-field
                          readonly
                          dense
                          outlined
                          :label="pengalaman.experienceStartYear"
                        >
                        </v-text-field>
                      </v-col>
                      <p class="mt-4">Ke</p>
                      <v-col cols="2">
                        <v-text-field
                          readonly
                          dense
                          outlined
                          :label="pengalaman.experienceEndYear"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field
                          readonly
                          dense
                          outlined
                          :label="pengalaman.experienceName"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2" class="mt-4">
                    <v-btn
                      style="margin-left: -60px"
                      fab
                      dark
                      color="error"
                      x-small
                      @click="clearPengalaman(i)"
                    >
                      <v-icon dark>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-row>
            </v-row>

            <!-- pendidilkan -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Pendidikan</span
                >
              </v-col>
              <v-row>
                <v-col cols="10">
                  <v-row class="ml-2">
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        v-model="educationStartYear"
                        item-value="year"
                        outlined
                      ></v-select>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        v-model="educationEndYear"
                        item-value="year"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        v-model="educationName"
                        placeholder="Keterangan"
                        outlined
                        :rules="[rules.counter]"
                        counter
                        maxlength="500"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="mt-4">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-1"
                    fab
                    dark
                    color="indigo"
                    x-small
                    @click="addPendidikan"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-for="(edu, i) in user2.doctor.educations"
                :key="i"
                v-show="newPendidikan"
              >
                <v-col cols="10">
                  <v-row class="ml-2">
                    <v-col cols="2" class="ml-3">
                      <v-text-field
                        dense
                        v-model="edu.educationStartYear"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        v-model="edu.educationEndYear"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        v-model="edu.educationName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="mt-4">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-1"
                    fab
                    dark
                    color="error"
                    x-small
                    @click="clearEdu(i)"
                  >
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>

            <!-- penghargaan -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Penghargaan</span
                >
              </v-col>
              <v-row>
                <v-col cols="10">
                  <v-row class="ml-1">
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        item-value="year"
                        v-model="awardYear"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        v-model="awardName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="mt-4">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-1"
                    fab
                    dark
                    color="indigo"
                    x-small
                    @click="addPenghargaan"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                v-show="newPenghargaan"
                v-for="(penghargaan, i) in user2.doctor.awards"
                :key="i"
              >
                <v-col cols="10">
                  <v-row class="ml-1">
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        item-value="year"
                        v-model="penghargaan.awardYear"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        v-model="penghargaan.awardName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="mt-4">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-1"
                    fab
                    dark
                    color="error"
                    x-small
                    @click="clearPenghargaan(i)"
                  >
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>

            <!-- pencapaian -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Pencapaian</span
                >
              </v-col>
              <v-row>
                <v-col cols="10">
                  <v-row class="ml-1">
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        item-value="year"
                        :items="years"
                        v-model="achievementYear"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        v-model="achievementName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-1"
                    fab
                    dark
                    color="indigo"
                    x-small
                    @click="addPencapaian"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                v-show="newPencapaian"
                v-for="(pencapaian, i) in user2.doctor.achievements"
                :key="i"
              >
                <v-col cols="10">
                  <v-row class="ml-1">
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        item-value="year"
                        :items="years"
                        v-model="pencapaian.achievementYear"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        v-model="pencapaian.achievementName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-1"
                    fab
                    dark
                    color="error"
                    x-small
                    @click="clearPencapaian(i)"
                  >
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
            
          </v-card>
          <v-row>
            <v-col offset-md="9">
              <!-- {{radios}} -->
              <v-btn
                class="btn-edit"
                @click="reset"
                outlined
                small
                color="primary"
                style="margin-right: 15px"
                >Reset</v-btn
              >
              <v-btn
                class="btn-edit"
                :disabled="confirmDisabled"
                small
                @click="submited"
                color="success"
                >Submit</v-btn
              >
              <!-- <v-btn v-else-if="radios === 'spesialis'" class="btn-edit" small @click="submited" color="success"
                >Submit</v-btn
              > -->
            </v-col>
          </v-row>
        </b-card>
        <!-- <v-card>
          <div class="kategoriSps col-sm-6">
            <span
              class="col-md-6"
              style="font-family: Nunito;font-size: 20px;font-style: normal;font-weight: 400;line-height: 27px;letter-spacing: 0em;text-align: left;"
            >Kategori</span>
          </div>
          <div class="detailPengalaman">
            <v-select outlined></v-select>
          </div>
          <div class="pengalaman col-sm-6">
            <span
              class="col-md-6"
              style="font-family: Nunito;font-size: 20px;font-style: normal;font-weight: 400;line-height: 27px;letter-spacing: 0em;text-align: left;"
            >Pengalaman & Pendidikan</span>
          </div>
          <div class="detailPengalaman">
            <v-row>
              <v-col cols="3">
                <v-select placeholder="Tahun" outlined></v-select>
              </v-col>
              <v-col>
                <v-text-field placeholder="Keterangan" outlined></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="pengalaman col-sm-6">
            <span
              class="col-md-6"
              style="font-family: Nunito;font-size: 20px;font-style: normal;font-weight: 400;line-height: 27px;letter-spacing: 0em;text-align: left;"
            >Penghargaan & Pencapaian</span>
          </div>
          <div class="detailPengalaman">
            <v-row>
              <v-col cols="3">
                <v-select placeholder="Tahun" outlined></v-select>
              </v-col>
              <v-col>
                <v-text-field placeholder="Keterangan" outlined></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card>-->
        <!-- <b-card>
          <b-row>
            <b-col sm="3">
              <b-form-group label="Tanggal Mulai">
                <b-form-datepicker
                  v-model="user.dateStart"
                  :date-disabled-fn="dateDisabled"
                  locale="en"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group label="Tanggal Berakhir">
                <b-form-datepicker
                  v-model="user.dateEnd"
                  :date-disabled-fn="dateDisabled"
                  locale="en"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <v-data-table :headers="kategori" :items="jadwal" hide-default-footer></v-data-table>
          <v-card outlined>
            <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <th>Ketersediaan</th>
                    <th>From:</th>
                    <th>To:</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in jadwal" :key="item.name">
                    <td>{{item.name}}</td>
                    <td>
                      <vuetify-time-select v-model="item.timeStart"></vuetify-time-select>
                    </td>
                    <td>
                      <vuetify-time-select v-model="item.timeEnd"></vuetify-time-select>
                    </td>
                    <td>
                      <v-checkbox :v-model="item.check" label="Tutup"></v-checkbox>
                    </td>
                    <td>
                      <v-btn
                        :v-model="item.name"
                        @click="jadwal.push('aa')"
                        rounded
                        outlined
                        block
                      >{{item.slot}}</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
         <div align="right">
            <v-row>
              <v-col offset-md="9">
                <v-btn
                  class="btn-edit"
                  @click="reset"
                  medium
                  outlined
                  color="primary"
                  style="margin-right: 15px"
                >Reset</v-btn>
                <v-btn class="btn-edit" medium @click="submited" color="success">Submit</v-btn>
              </v-col>
            </v-row>
          </div>
        </b-card>-->
      </b-form>
    </b-container>
  </v-app>
</template>

<script>
  import scheduleService from "@/services/DoctorSchedule_service";
  import { required, sameAs } from "vuelidate/lib/validators";
  import { BFormInput } from "bootstrap-vue";
  import moment from "moment";
  import "flatpickr/dist/flatpickr.css";
  // import datePicker from "vue-bootstrap-datetimepicker";
  import axios from "axios";
  import DoctorServices from "@/services/doctor_services";
  export default {
    name: "dashboard",
    components: {
      BFormInput,
      // datePicker,
    },
    data: () => ({
      experienceShow: false,
      confirmDisabled: false,
      listSubSpesialisasi: [],
      listspesialisasi: [],
      radios: "",

      docDoctorHptAchievementId: "",
      // docDoctorHospitalId: "",
      achievementInstitutionName: "",
      achievementYear: null,
      achievementName: "",
      achievementDescription: "",

      docDoctorHptAwardId: "",
      // docDoctorHospitalId: "",
      awardInstitutionName: "",
      awardYear: null,
      awardName: "",
      awardDescription: "",

      docDoctorHptEducationId: "",
      educationInstitutionName: "",
      educationStartYear: 0,
      educationEndYear: 0,
      educationName: "",
      educationDescription: "",

      experienceInstitutionName: "",
      experienceStartYear: 0,
      experienceEndYear: 0,
      experienceName: "",
      experienceDescription: "",
      today: moment().format("YYYY-MM-DD"),
      foto: null,
      date: null,
      menu: false,
      menu1: false,
      menu2: false,
      modal: false,
      baruPengalaman: false,
      newPendidikan: false,
      newPenghargaan: false,
      newPencapaian: false,
      dataPendidikan: [],
      dataPengalaman: [],
      dataPenghargaan: [],
      dataPencapaian: [],
      yAwal: "",
      yAkhir: "",
      yearsAwal: "",
      yearsAkhir: "",
      yearsPencapaian: "",
      yearsPenghargaan: "",

      time: "20:30",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 500 || "Max 20 characters",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || "E-mail must be valid",
      ],
      user: {
        selected: null,
        spesialis: "",
        fullName: "",
        Foto: null,
        email: "",
        mobilePhone: "",
        Password: "",
        sipNumber: "",
        Confirm: "",
        Biaya: "",
        simple: "",
        birthDate: null,
        hospitalEmployeeNumber: "",
        dateStart: "",
        dateEnd: "",
        options: {
          // https://momentjs.com/docs/
        },
      },
      user2: {
        doctor: {
          address: "",
          birthDate: "",
          dateSchedule: null,
          daySchedule: null,
          // docDoctorHospitalId: "",
          docDoctorId: "",
          docMstMedicSpecializationId: "",
          docMstMedicSubSpcId: "",
          email: "",
          fullName: "",
          gender: "male",
          geoSubDistrictId: "",
          hospitalEmployeeNumber: "",
          hptHospitalId: "MEDISTRA",
          idNumber: "",
          mobilePhone: "",
          passportNumber: "",
          postalCode: "",
          profilePictureUrl: "",
          qualifications: null,
          secUserId: "",
          sipNumber: "",
          taxNumber: "",
          consultationFee: "",
          consultationSpecialFee: "",
          awards: [],
          achievements: [],
          educations: [],
          experiences: [],
        },
        password: "",
        rePassword: "",
      },
    }),
    validations: {
      password: {
        required,
      },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
    },

    mounted() {
      this.spesial();
    },
    methods: {
      clearPencapaian(item) {
        console.log(item);
        this.user2.doctor.achievements.splice(item, 1);
        console.log(this.user2.doctor.achievements);
        // if (this.user2.doctor.experiences === 0) {
        //   this.experienceShow = false;
        // }
      },
      clearPengalaman(item) {
        console.log(item);
        this.user2.doctor.experiences.splice(item, 1);
        console.log(this.user2.doctor.experiences);
        // if (this.user2.doctor.experiences === 0) {
        //   this.experienceShow = false;
        // }
      },
      clearPenghargaan(item) {
        console.log(item);
        this.user2.doctor.awards.splice(item, 1);
        console.log(this.user2.doctor.awards);
        // if (this.user2.doctor.experiences === 0) {
        //   this.experienceShow = false;
        // }
      },
      clearEdu(i) {
        console.log(i);
        this.user2.doctor.educations.splice(i, 1);
        console.log(this.user2.doctor.educations);
        // if (this.user2.doctor.education === 0) {
        //   this.newPendidikan = false;
        // }
      },
      submitedGeneral() {
        console.log("general");
        this.user2.doctor.docMstMedicSpecializationId = "GENERAL";
        console.log(this.user2.doctor.docMstMedicSpecializationId);
      },
      selectdoctorspc() {
        this.listSubSpesialisasi = [];
        this.user2.doctor.docMstMedicSubSpcId = "";
        console.log(this.user2.doctor.docMstMedicSpecializationId);
        axios
          .get(
            `https://dev-api.klinisia.id/general/doctor/medic-specialization/${this.user2.doctor.docMstMedicSpecializationId}/medic-sub-spc`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + window.localStorage.getItem("shortToken"),
              },
            },
          )
          .then((res) => {
            console.log(res);
            var data = res.data.payload;
            data.map((e) => {
              this.listSubSpesialisasi.push({
                value: e.docMstMedicSubSpcId,
                text: e.subSpcName,
              });
            });
          });
      },
      spesial() {
        scheduleService.getSPecialization().then((res) => {
          console.log(res);
          var data = res.data.payload;
          var realdata = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].docMstMedicSpecializationId !== "GENERAL") {
              realdata.push(data[i]);
            }
          }
          realdata.map((e) => {
            this.listspesialisasi.push({
              value: e.docMstMedicSpecializationId,
              text: e.specializationName,
            });
          });
          console.log(this.listspesialisasi);
        });
      },

      addPengalaman() {
        if (
          this.experienceStartYear === 0 ||
          this.experienceEndYear === 0 ||
          this.experienceName === ""
        ) {
          this.$toast.error("Lengkapi Data Pengalaman Terlebih Dahulu", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        } else {
          this.experienceShow = true;
          this.user2.doctor.experiences.push({
            experienceInstitutionName: "",
            experienceStartYear: this.experienceStartYear,
            experienceEndYear: this.experienceEndYear,
            experienceName: this.experienceName,
            experienceDescription: "",
          });
          this.experienceStartYear = 0;
          this.experienceEndYear = 0;
          this.experienceName = "";
        }
        console.log(this.user2.doctor.experiences);
      },
      addPenghargaan() {
        if (this.awardYear === 0 || this.awardName === "") {
          this.$toast.error("Lengkapi Data Penghargaan Terlebih Dahulu", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        } else {
          this.user2.doctor.awards.push({
            docDoctorHptAwardId: "",
            // docDoctorHospitalId: "",
            awardInstitutionName: "",
            awardYear: this.awardYear,
            awardName: this.awardName,
            awardDescription: this.awardName,
          });
          this.awardYear = 0;
          this.awardName = "";
          this.newPenghargaan = true;
        }
        console.log(this.user2.doctor.awards);
      },
      addPendidikan() {
        if (
          this.educationStartYear === 0 ||
          this.educationEndYear === 0 ||
          this.educationName === ""
        ) {
          this.$toast.error("Lengkapi Data Pendidikan Terlebih Dahulu", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        } else {
          this.user2.doctor.educations.push({
            docDoctorHptEducationId: "",
            // docDoctorHospitalId: "",
            educationInstitutionName: "",
            educationStartYear: this.educationStartYear,
            educationEndYear: this.educationEndYear,
            educationName: this.educationName,
            educationDescription: this.educationName,
          });

          this.educationStartYear = 0;
          this.educationEndYear = 0;
          this.educationName = "";
          this.newPendidikan = true;
        }
        console.log(this.user2.doctor.educations);
      },
      addPencapaian() {
        if (this.achievementYear === 0 || this.achievementName === "") {
          this.$toast.error("Lengkapi Data Pencapaian Terlebih Dahulu", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        } else {
          this.user2.doctor.achievements.push({
            docDoctorHptAchievementId: "",
            achievementInstitutionName: "",
            achievementYear: this.achievementYear,
            achievementName: this.achievementName,
            achievementDescription: this.achievementName,
          });
          this.achievementYear = 0;
          this.achievementName = "";
          this.newPencapaian = true;
        }
        console.log(this.user2.doctor.achievements);
      },
      upload(event) {
        console.log("VALUE " + JSON.stringify(event));
        console.log(event.target);
        this.foto = event.target.files[0];
        console.log(this.foto);
      },
      submited() {
        if (this.radios === "") {
          this.$toast.error("isi Kategori Dokter Terlebih Dahulu", {
            type: "error",
            position: "top-right",
            duration: 4000,
          });
        } else {
          console.log("spesialis");
          console.log(this.radios);



          if (this.user2.doctor.consultationSpecialFee === "") {
            this.user2.doctor.consultationSpecialFee = 0;
          }
          
          if (this.radios === "GENERAL") {
            this.user2.doctor.docMstMedicSpecializationId = "GENERAL";
            console.log("ini general");
          }
          console.log(this.user2);
          console.log(this.user2.doctor.docMstMedicSpecializationId);
          if (this.user2.doctor.docMstMedicSpecializationId === null) {
            this.$toast.error("Pilih Jenis Dokter Lebih Dulu", {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
          
          } else if (parseFloat(this.user2.doctor.consultationSpecialFee) != 0 && parseFloat(this.user2.doctor.consultationSpecialFee) < parseFloat(this.user2.doctor.consultationFee)) {
            this.$toast.error("`Harga coret` tidak boleh kurang dari `biaya konsultasi` ", {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
          } else if (this.user2.doctor.fullName === "") {
            this.$toast.error("Nama Dokter Tidak Boleh Kosong", {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
          } else if (this.user2.doctor.email === "") {
            this.$toast.error("Email Tidak Boleh Kosong", {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
          } else if (
            !this.user2.doctor.email.includes(".") ||
            !this.user2.doctor.email.includes("@")
          ) {
            this.$toast.error("format email tidak sesuai", {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
          } else if (this.user2.doctor.sipNumber === "") {
            this.$toast.error("SIP Tidak Boleh Kosong", {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
          } else if (this.user2.doctor.mobilePhone === "") {
            this.$toast.error("No Handphone Tidak Boleh Kosong", {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
          } else if (this.user2.doctor.hospitalEmployeeNumber === "") {
            this.$toast.error("NIP Dokter Tidak Boleh Kosong", {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
          } else {
            this.confirmDisabled = true;
            if (this.radios === "spesialis") {
              if (this.user2.doctor.docMstMedicSpecializationId === "") {
                this.$toast.open({
                  message: "isi Spesialis dan sub spesialisasi terlebih dahulu",
                  position: "top-right",
                  type: "error",
                  duration: 6000,
                });
                this.confirmDisabled = false;
              }
             
              else {
                console.log(JSON.stringify(this.user2));
                axios
                  .post(
                    " https://dev-api.klinisia.id/admin/doctor/register",
                    this.user2,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization:
                          "Bearer " + window.localStorage.getItem("shortToken"),
                      },
                    },
                  )
                  .then((response) => {
                    if (response.data.errors.length === 0) {
                      console.log(response);
                      console.log(response);
                      window.localStorage.setItem(
                        "secuserId2",
                        response.data.payload.doctor.secUserId,
                      );
                      window.localStorage.setItem(
                        "docDoctorHospitalId",
                        response.data.payload.doctor.docDoctorHospitalId,
                      );
                      // var secId = response.data.payload.doctor.docDoctorHospitalId;
                      console.log("ini getlocal");
                      console.log(window.localStorage.getItem("secuserId2"));
                      var id2 = window.localStorage.getItem("secuserId2");
                      console.log(id2);
                      console.log(window.localStorage.getItem("secuserId2"));
                      // console.log(id2);
                      let iddoc =
                        response.data.payload.doctor.docDoctorHospitalId;
                      let formData = new FormData();
                      formData.append("profilePicture", this.foto);
                      let data = {
                        id: iddoc,
                        body: formData,
                      };
                      console.log(data);
                      if (this.foto !== null) {
                        console.log(data);
                        DoctorServices.postPp(data)
                          .then((response) => {
                            console.log();
                            console.log("foto sukses", response);
                            console.log("id setelah response");
                            console.log(id2);
                            console.log(
                              ` https://dev-api.klinisia.id/storage/file/profile-picture/${id2}`,
                            );
                          })
                          .catch((err) => {
                            console.log("foto error", err);
                            this.$toast.error(err, {
                              type: "error",
                              position: "top-right",
                              duration: 5000,
                            });
                            console.log(this.foto);
                          });
                      }
                      

                      this.$toast.success("Dokter Baru Berhasil Di Simpan", {
                        type: "success",
                        position: "top-right",
                        duration: 5000,
                      });
                      this.reset();
                    }

                    console.warn("response", response);

                    if (response.data.errors[0].message !== "") {
                      console.log(response.data.errors.length);
                      this.$toast.error(
                        response.data.errors[0].field +
                          " " +
                          response.data.errors[0].message,
                        {
                          type: "error",
                          position: "top-right",
                          duration: 4000,
                        },
                      );
                    }
                    this.confirmDisabled = false;
                  })
                  .catch((error) => {
                    console.log("post error");
                    console.log(error);
                    this.confirmDisabled = false;
                  });
              }
            } else {
              axios
                .post(
                  " https://dev-api.klinisia.id/admin/doctor/register",
                  this.user2,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization:
                        "Bearer " + window.localStorage.getItem("shortToken"),
                    },
                  },
                )
                .then((response) => {
                  if (response.data.errors.length === 0) {
                    console.log(response);
                    console.log(response);
                    window.localStorage.setItem(
                      "secuserId2",
                      response.data.payload.doctor.secUserId,
                    );

                    console.log("ini getlocal");
                    console.log(window.localStorage.getItem("secuserId2"));
                    var id2 = window.localStorage.getItem("secuserId2");
                    console.log(window.localStorage.getItem("secuserId2"));
                    // console.log(id2);
                    let formData = new FormData();
                    formData.append("profilePicture", this.foto);
                    console.log("ini form Data", formData);
                    console.log("ini foto", this.foto);
                    let iddoc =
                      response.data.payload.doctor.docDoctorHospitalId;
                    if (this.foto !== null) {
                      let data = {
                        id: iddoc,
                        body: formData,
                      };
                      DoctorServices.postPp(data)
                        .then((response) => {
                          console.log("foto sukses");
                          console.log(response);
                          console.log("id setelah response");
                          console.log(id2);
                          console.log(
                            ` https://dev-api.klinisia.id/storage/file/profile-picture/${id2}`,
                          );
                        })
                        .catch((err) => {
                          console.log("foto error", err);
                          // this.$toast.error(err, {
                          //   type: "error",
                          //   position: "top-right",
                          //   duration: 5000,
                          // });
                          console.log(this.foto);
                        });
                    }
                    this.$toast.success("Dokter Baru Berhasil Di Simpan", {
                      type: "success",
                      position: "top-right",
                      duration: 5000,
                    });
                    this.reset();
                  }

                  console.warn("response", response);

                  if (response.data.errors[0].message !== "") {
                    console.log(response.data.errors.length);
                    this.$toast.error(
                      response.data.errors[0].field +
                        " " +
                        response.data.errors[0].message,
                      {
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                      },
                    );
                  }
                  this.confirmDisabled = false;
                })
                .catch((error) => {
                  console.log("post error");
                  console.log(error);
                  this.confirmDisabled = false;
                });
            }
          }
        }
      },
      reset() {
        this.user2.doctor.docMstMedicSpecializationId = null;
        this.user2.doctor.fullName = "";
        this.user2.doctor.mobilePhone = "";
        this.user2.doctor.email = "";
        this.user2.doctor.sipNumber = "";
        this.user2.doctor.hospitalEmployeeNumber = "";
        this.user2.doctor.birthDate = null;
        this.$refs.fileinput.reset();
        this.pengalaman = [
          {
            // "docDoctorHptExperienceId": "",
            // "docDoctorHospitalId": "",
            experienceInstitutionName: "",
            experienceStartYear: 0,
            experienceEndYear: 0,
            experienceName: "",
            experienceDescription: "",
          },
        ];
        this.education = [
          {
            docDoctorHptEducationId: "",
            // docDoctorHospitalId: "",
            educationInstitutionName: "",
            educationStartYear: 0,
            educationEndYear: 0,
            educationName: "",
            educationDescription: "",
          },
        ];
        this.penghargaan = [
          {
            docDoctorHptAwardId: "",
            // docDoctorHospitalId: "",
            awardInstitutionName: "",
            awardYear: 0,
            awardName: "",
            awardDescription: "",
          },
        ];
        this.pencapaian = [
          {
            docDoctorHptAchievementId: "",
            // docDoctorHospitalId: "",
            achievementInstitutionName: "",
            achievementYear: 0,
            achievementName: "",
            achievementDescription: "",
          },
        ];
        this.radios = "";
      },
      dateDisabled(ymd, date) {
        const weekday = date.getDay();
        return weekday === 0 || weekday === 6;
      },
    },
    computed: {
      passwordcheck() {
        //   return this.user.Confirm.length > 0 && this.user.Confirm === this.user.Password
        return (
          this.user2.doctor.email.includes("@") &&
          this.user2.doctor.email.includes(".com") &&
          this.user2.doctor.email !== ""
        );
      },
      PhotoRule() {
        return this.user.Foto < 2000;
      },
      years() {
        const year = new Date().getFullYear();
        return Array.from(
          { length: year - 1950 },
          (value, index) => 1951 + index,
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .example::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .example {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  @import "../../scss/dashboard.scss";
</style>
