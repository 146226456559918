var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"background"},[_c('v-container',{staticClass:"mt-n8"},[_c('v-card',{staticClass:"cardStyle"},[_c('v-row',[_c('v-col',{staticClass:"search",attrs:{"cols":"auto"}},[_c('h1',{staticClass:"text-title"},[_vm._v("Total Antrian")])]),_c('v-col',{staticClass:"search",attrs:{"cols":"auto"}},[_c('p',{staticClass:"bgTotalPatient mt-1"},[_vm._v(_vm._s(_vm.totalPatientList))])]),_c('v-col',{attrs:{"offset":"5"}},[_c('p',{staticClass:"mt-3 text-title",attrs:{"offset":"5"}},[_vm._v(" "+_vm._s(_vm.queueDate)+", "+_vm._s(_vm.queueDay)+" "+_vm._s(_vm.queueMonth)+" "+_vm._s(_vm.queueYear)+" ")])])],1)],1)],1),_c('v-container',[_c('v-card',{staticClass:"cardStyle"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"items-per-page":_vm.itemsPerPage,"page":_vm.page,"headers":_vm.header,"items":_vm.indexof,"search":_vm.Search,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.foto",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"30"}},[_c('img',{staticClass:"img-card",attrs:{"src":'https://dev-api.klinisia.id/storage/file/public/profile-picture/' +
                        item.secUserId,"alt":"foto"}})])]}},{key:"item.paymentDateByDate",fn:function(ref){
                        var item = ref.item;
return [_c('a',[_vm._v(_vm._s(_vm._f("orderDate")(item.paymentDate)))])]}},{key:"item.paymentDateByTime",fn:function(ref){
                        var item = ref.item;
return [_c('a',[_vm._v(_vm._s(_vm._f("orderTime")(item.paymentDate)))])]}}],null,true)})]],2)],1)],1)],1),_c('v-pagination',{staticClass:"mt-5",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }