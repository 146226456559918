<template>
  <v-app class="background">
    <b-container>
      <b-form>
        <b-card class="content">
          <b-card-title class="title">
            <b-row>
              <b-col>
                <h1 class="text-title">Lihat Profile Dokter</h1>
              </b-col>
            </b-row>
          </b-card-title>

          <b-card-text style="margin-top: 24px">
            <!-- Dokter Detail -->
            <v-row>
              <v-col lg="3" md="6" sm="12">
                <v-avatar class="mt-2" rounded size="200">
                  <img :src="ava" alt="foto" />
                </v-avatar>
              </v-col>

              <v-col lg="3" md="6" sm="12" align-self="center">
                <h4>{{ newListDokter.fullName }}</h4>
                <p class="text-spesialis">
                  {{ spesialisaiDoc }}
                </p>
                <v-btn
                  v-show="editButton"
                  rounded
                  x-medium
                  outlined
                  color="teal"
                  @click="edit"
                  block
                >
                  <v-icon dark right class="ma-2"> mdi-pencil </v-icon>
                  Edit
                </v-btn>
              </v-col>
            </v-row>

            <b-form-group>
              <!-- Form Lihat -->
              <b-row style="margin-top: 24px">
                <b-col md="12" sm="12">
                  <b-form-group class="text-label" label="Nama Lengkap *">
                    <b-form-input
                      type="text"
                      v-model="newListDokter.fullName"
                      placeholder="Masukkan Nama Lengkap"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Tanggal Lahir (DD-MM-YY) *"
                  >
                    <b-form-input
                      v-model="newListDokter.birthDate"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Biaya Konsultasi / 30 menit * "
                  >
                    <b-input-group
                      prepend="Rp"
                      label="Biaya Konsultasi / 30 menit * "
                    >
                      <b-form-input
                        id="biaya"
                        type="number"
                        v-model="newListDokter.consultationFee"
                        placeholder=""
                        disabled
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Harga Coret"
                  >
                    <b-input-group
                      prepend="Rp"
                      label="Harga Coret"
                    >
                      <b-form-input
                        id="consultationSpecialFee"
                        type="number"
                        v-model="newListDokter.consultationSpecialFee"
                        placeholder=""
                        disabled
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Tanggal Lahir (DD-MM-YY) *"
                  >
                    <b-form-input
                      v-model="newListDokter.birthDate"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="Email *">
                    <b-form-input
                      v-model="newListDokter.email"
                      placeholder="masukkan email dokter"
                      type="email"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                
              </b-row>
              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="No. Handphone">
                    <b-form-input
                      v-model="newListDokter.mobilePhone"
                      placeholder="masukkan email dokter"
                      type="number"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Hospital Employee Number *"
                  >
                    <b-form-input
                      v-model="newListDokter.hospitalEmployeeNumber"
                      placeholder
                      required
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group class="text-label" label="No. SIP *">
                    <b-form-input
                      v-model="newListDokter.sipNumber"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-card-text>
          <v-card v-if="radios !== 'GENERAL'">
            <v-row>
              <v-col cols="12" class="ml-4">
                <span
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Kategori</span
                >
              </v-col>
              <v-col cols="4" class="ml-4">
                <!-- <p>{{newListDokter.docMstMedicSpecializationId}}</p> -->
                <v-text-field
                  placeholder=""
                  outlined
                  dense
                  disabled
                  v-model="newListDokter.specializationName"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  placeholder=""
                  outlined
                  dense
                  disabled
                  v-model="newListDokter.subSpcName"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- pengalaman -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Pengalaman</span
                >
              </v-col>
              <v-row>
                <v-col cols="10" v-if="newListDokter.experiences.length === 0">
                  <v-row class="ml-2">
                    <v-col cols="2" class="ml-3">
                      <v-text-field
                        disabled
                        dense
                        placeholder="Tahun"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-text-field
                        disabled
                        dense
                        placeholder="Tahun"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        disabled
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10" v-else>
                  <v-row
                    class="ml-2"
                    v-for="(pengalaman, index) in newListDokter.experiences"
                    :key="index"
                  >
                    <v-col cols="2" class="ml-3">
                      <v-select
                        disabled
                        dense
                        placeholder="Tahun"
                        outlined
                        :items="years"
                        item-value="year"
                        v-model="pengalaman.experienceStartYear"
                      ></v-select>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-select
                        disabled
                        dense
                        placeholder="Tahun"
                        :items="years"
                        value="year"
                        outlined
                        v-model="pengalaman.experienceEndYear"
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        disabled
                        placeholder="Keterangan"
                        outlined
                        dense
                        v-model="pengalaman.experienceName"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="2">
                  <v-btn class="mt-1" fab dark color="indigo" x-small @click="addPengalaman">
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                    </v-col>-->
                  </v-row>
                </v-col>
              </v-row>
            </v-row>

            <!-- pendidilkan -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Pendidikan</span
                >
              </v-col>
              <v-row>
                <v-col cols="10" v-if="newListDokter.educations.length === 0">
                  <v-row class="ml-1">
                    <v-col cols="2" class="ml-3">
                      <v-text-field
                        disabled
                        dense
                        placeholder="Tahun"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-text-field
                        disabled
                        dense
                        placeholder="Tahun"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        disabled
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10" v-else>
                  <v-row
                    v-for="(edu, i) in newListDokter.educations"
                    :key="i"
                    class="ml-2"
                  >
                    <v-col cols="2" class="ml-3">
                      <v-select
                        disabled
                        dense
                        placeholder="Tahun"
                        :items="years"
                        v-model="edu.educationStartYear"
                        value="year"
                        outlined
                      ></v-select>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-select
                        disabled
                        dense
                        placeholder="Tahun"
                        :items="years"
                        v-model="edu.educationEndYear"
                        value="year"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        disabled
                        v-model="edu.educationName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>

            <!-- penghargaan -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Penghargaan</span
                >
              </v-col>
              <v-row>
                <v-col cols="10" v-if="newListDokter.awards.length === 0">
                  <v-row class="ml-1">
                    <v-col cols="2" class="ml-3">
                      <v-text-field
                        disabled
                        dense
                        placeholder="Tahun"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        disabled
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10" v-else>
                  <v-row
                    v-for="(penghargaan, i) in newListDokter.awards"
                    :key="i"
                    class="ml-1"
                  >
                    <v-col cols="2" class="ml-3">
                      <v-select
                        disabled
                        dense
                        placeholder="Tahun"
                        :items="years"
                        item-value="year"
                        v-model="penghargaan.awardYear"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        disabled
                        v-model="penghargaan.awardName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>

            <!-- pencapaian -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Pencapaian</span
                >
              </v-col>
              <v-row>
                <v-col cols="10" v-if="newListDokter.achievements.length === 0">
                  <v-row class="ml-1">
                    <v-col cols="2" class="ml-3">
                      <v-text-field
                        disabled
                        dense
                        placeholder="Tahun"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        disabled
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10" v-else>
                  <v-row
                    class="ml-1"
                    v-for="(pencapaian, i) in newListDokter.achievements"
                    :key="i"
                  >
                    <v-col cols="2" class="ml-3">
                      <v-select
                        disabled
                        dense
                        placeholder="Tahun"
                        item-value="year"
                        :items="years"
                        v-model="pencapaian.achievementYear"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        disabled
                        v-model="pencapaian.achievementName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="2">
                  <v-btn class="mt-1" fab dark color="indigo" x-small @click="addPencapaian">
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                    </v-col>-->
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-card>
        </b-card>
      </b-form>
    </b-container>
  </v-app>
</template>

<script>
  import scheduleService from "@/services/DoctorSchedule_service";
  import { BFormInput } from "bootstrap-vue";
  import "flatpickr/dist/flatpickr.css";

  import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
  import axios from "axios";
  import DoctorServices from "@/services/doctor_services";

  export default {
    name: "lihat",
    // props:['data'],
    components: {
      BFormInput,
      // flatPicker
      // datePicker
    },
    data: () => ({
      editButton: false,
      login: {},
      radios: "",
      listspesialisasi: [],
      ava: null,
      spesialisaiDoc: "",
      newListDokter: {
        docDoctorHospitalId: "",
        docDoctorId: "",
        hptHospitalId: "",
        docMstMedicSpecializationId: "",
        sipNumber: "",
        secUserId: "",
        idNumber: "",
        taxNumber: "",
        passportNumber: "",
        fullName: "",
        gender: "male",
        birthDate: "2021-01-28",
        mobilePhone: "",
        email: "",
        hospitalEmployeeNumber: "",
        geoSubDistrictId: "",
        postalCode: "",
        address: "",
        ratingStar: 0,
        awards: [
          {
            awardInstitutionName: "",
            awardYear: 0,
            awardName: "",
            awardDescription: "",
          },
        ],
        achievements: [
          {
            achievementInstitutionName: "",
            achievementYear: 0,
            achievementName: "",
            achievementDescription: "",
          },
        ],
        educations: [
          {
            educationInstitutionName: "",
            educationStartYear: 0,
            educationEndYear: 0,
            educationName: "",
            educationDescription: "",
          },
        ],
        experiences: [
          {
            experienceInstitutionName: "",
            experienceStartYear: 0,
            experienceEndYear: 0,
            experienceName: "",
            experienceDescription: "",
          },
        ],
        profilePictureUrl: "",
      },
      time: "20:30",
      user: {
        selected: "",
        fullName: "",
        Foto: null,
        email: "",
        mobilePhone: "",
        Password: "",
        sipNumber: "",
        Confirm: "",
        Biaya: "",
        simple: "",
        birthDate: null,
        dateStart: "",
        dateEnd: "",
        options: {
          // https://momentjs.com/docs/
        },
      },
      user2: {
        doctor: {
          docDoctorHospitalId: "",
          docDoctorId: "",
          hptHospitalId: "MEDISTRA",
          docDoctorMedicalSpecialistId: "UMUM",
          sipNumber: "123456987",
          secUserId: "",
          idNumber: "",
          taxNumber: "",
          passportNumber: "",
          fullName: "qertyui",
          gender: "male",
          birthDate: "2021-01-22T07:58:52.097Z",
          mobilePhone: "62812876",
          email: "q1@gmail.com",
          geoSubDistrictId: "",
          postalCode: "",
          address: "",
          qualifications: null,
          awards: null,
          dateSchedule: null,
          daySchedule: null,
          profilePictureUrl: "",
        },
        password: "",
        rePassword: "",
      },
      data: [],
      // user2:{},
      kategori: [
        {
          text: "Ketersediaan",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "From:",
        },
        {
          text: "To:",
        },
      ],
      jadwal: [
        {
          name: "Senin",
          check: "Senin",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Selasa",
          check: "Selasa",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Rabu",
          check: "Rabu",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Kamis",
          check: "Kamis",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Jumat",
          check: "Jumat",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Sabtu",
          check: "Sabtu",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Minggu",
          check: "Miinggu",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
      ],
    }),
    mounted() {
      this.loginData();
      this.editMenu();
      this.getData();
      this.spesial();
      this.namaSpesialisasi();
    },
    watch: {},
    methods: {
      namaSpesialisasi() {},
      editMenu() {
        var dataDoktor = this.login.features.filter(
          (item) => item.featureName === "Doctor Maintenance",
        );
        console.log("data doktor", dataDoktor);
        if (dataDoktor[0].update === true) {
          this.editButton = true;
        }
      },
      loginData() {
        this.login = JSON.parse(window.localStorage.getItem("loginData"));
        console.log(this.login.features);
      },
      spesial() {
        scheduleService.getSPecialization().then((res) => {
          console.log(res);
          var data = res.data.payload;
          var realdata = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].docMstMedicSpecializationId !== "GENERAL") {
              realdata.push(data[i]);
            }
          }
          realdata.map((e) => {
            this.listspesialisasi.push({
              value: e.docMstMedicSpecializationId,
              text: e.specializationName,
            });
          });
          console.log(this.listspesialisasi);
        });
      },
      getData() {
        let id = window.localStorage.getItem("docDoctorHospitalId");
        DoctorServices.getDoctorById(id)
          .then((response) => {
            console.log("lihat res ," + JSON.stringify(response));

            this.newListDokter = response.data.payload;
            this.radios = this.newListDokter.docMstMedicSpecializationId;
            // this.ava =
            //   this.$ppUrl +
            //   this.newListDokter.secUserId +
            //   "/?token=" +
            //   window.localStorage.getItem("shortToken");
            this.ava = this.newListDokter.profilePictureUrl;
            console.log(this.ava);
            console.log("list spesialisasi", this.listspesialisasi);
            console.log("list dokter", this.newListDokter);
            if (this.newListDokter.docMstMedicSpecializationId === "GENERAL") {
              this.spesialisaiDoc = "Dokter Umum";
            } else {
              this.listspesialisasi.filter((e) => {
                if (
                  e.value === this.newListDokter.docMstMedicSpecializationId
                ) {
                  this.spesialisaiDoc = e.text;
                }
              });
            }
          })
          .catch((err) => console.log(err));
      },
      edit() {
        this.$router.push("/Edit");
      },
      submited() {
        console.log("ini hospital");
        console.log(this.newListDokter.docDoctorHospitalId);
        var del = this.newListDokter.docDoctorHospitalId;
        console.log("this is del" + del);
        // ' https://dev-api.klinisia.id/admin/doctor/edit/' + this.del

        // console.log(window.localStorage.getItem('shortToken'));
        axios
          .put(
            " https://dev-api.klinisia.id/admin/doctor/edit/" + del,
            this.newListDokter,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + window.localStorage.getItem("shortToken"),
              },
            },
          )
          .then((response) => {
            console.log("sukses");
            console.log(response);
            // this.newListDokter = response
          })
          .catch((err) => {
            console.log("PUT error");
            console.log(err);
          });

        console.log(this.newListDokter.docDoctorHospitalId);
      },
    },
    //     dateDisabled(ymd, date){
    //         const weekday = date.getDay()
    //         return weekday === 0 || weekday === 6
    //     }
    //   },
    computed: {
      passwordcheck() {
        return (
          this.user.Confirm.length > 0 &&
          this.user.Confirm === this.user.Password
        );
      },
      PhotoRule() {
        return this.user.Foto < 2000;
      },
      years() {
        const year = new Date().getFullYear();
        return Array.from(
          { length: year - 1950 },
          (value, index) => 1951 + index,
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../scss/dashboard.scss";
  .text-spesialis {
    font-family: Nunito;
    font-size: 16px;
  }
  .text-title {
    font-family: Nunito;
    font-size: 22px;
  }
</style>
