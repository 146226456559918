<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col>
            <h5 class="mt-4">HOSPITAL</h5>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ml-1">
          <v-col>
            <h5>Data Search</h5>
            <v-row>
              <v-col cols="3" class="mt-2">
                <p>Search</p>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="search"
                  append-icon="mdi-magnify"
                  outlined
                  v-model="search"
                  @keyup.enter="onSearch"
                  rounded
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ml-2">
          <v-col cols="2" class="mr-11">
            <v-btn
              v-if="menuList[0].create === true"
              small
              class="mt-1"
              color="success"
              to="/AddHospital"
              dark
              ><v-icon dark small> mdi-plus </v-icon>Tambah Rumah Sakit</v-btn
            >
          </v-col>
          <v-col cols="2" class="mb-6">
            <v-select
              hide-details=""
              @change="selectedPage()"
              v-model="pageNumber"
              :items="slot"
              outlined
              dense
              class="text"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-3">
          <v-col>
            <div>
              <v-data-table
                :headers="headers()"
                :items="dataRs"
                :server-items-length="pageCount"
                :search="search"
                class="elevation-1"
                dense
                hide-default-footer
              >
                <template v-slot:[`item.foto`]="{ item }">
                  <!-- <v-avatar size="50" color="white"> -->
                  <v-img :src="item.logoUrl" alt="foto" class="image"></v-img>
                  <!-- </v-avatar> -->
                </template>
                <template v-slot:[`item.aksi`]="{ item }">
                  <td>
                    <v-btn
                      color="primary"
                      class="mr-1"
                      dark
                      x-small
                      @click="edit(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-pencil </v-icon>
                      <p v-if="menuList[0].update === true" class="mt-4">
                        Ubah
                      </p>
                      <p v-if="menuList[0].update === false" class="mt-4">
                        lihat
                      </p>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      v-if="menuList[0].delete === true"
                      color="error"
                      x-small
                      dark
                      @click="dels(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-delete </v-icon
                      >Hapus</v-btn
                    >
                  </td>
                </template>
              </v-data-table>
              <v-row>
                <v-col>
                  <v-pagination
                    circle
                    v-model="page"
                    :length="totalpage.totalPages"
                    :total-visible="7"
                    @input="choosePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-dialog v-model="dialogEdit" persistent max-width="1500">
          <v-card>
            <v-form>
              <v-row class="ml-2 mt-4">
                <v-col>
                  <h5 class="mt-3">Edit Rumah Sakit</h5>
                </v-col>
              </v-row>
              <v-divider></v-divider>
             
              <v-row class="ml-2 mt-4">
                <v-col cols="6">
                   <p>Logo</p>
                  <v-img
                    max-height="150"
                    max-width="150"
                    :src="logoRs"
                    class="mb-3"
                  ></v-img>
                  <b-form-group class="text-label">
                    <b-form-file
                      @change="upload($event)"
                      accept="image/*"
                      placeholder="Ubah Logo"
                      ref="fileinput"
                    ></b-form-file>
                  </b-form-group>
                </v-col>
              </v-row>

              <v-row class="ml-2 mr-4">
                  <v-col cols="4">
                     <p>Banner</p>
                  </v-col>
              </v-row>
               <v-row class="ml-2 mr-4">
                <v-col cols="4">
                  <v-img
                    max-height="150"
                    max-width="150"
                    :src="logoRs"
                    class="mb-3"
                  ></v-img>
                  <b-form-group class="text-label">
                    <b-form-file
                      @change="upload($event)"
                      accept="image/*"
                      placeholder="Ubah Banner"
                      ref="fileinput"
                    ></b-form-file>
                  </b-form-group>

                </v-col>

                <v-col cols="4">
                   
                  <v-img
                    max-height="150"
                    max-width="150"
                    :src="logoRs"
                    class="mb-3"
                  ></v-img>
                  <b-form-group class="text-label">
                    <b-form-file
                      @change="upload($event)"
                      accept="image/*"
                      placeholder="Ubah Banner"
                      ref="fileinput"
                    ></b-form-file>
                  </b-form-group>
                </v-col>

                <v-col cols="4">
                  <v-img
                    max-height="150"
                    max-width="150"
                    :src="logoRs"
                    class="mb-3"
                  ></v-img>
                  <b-form-group class="text-label">
                    <b-form-file
                      @change="upload($event)"
                      accept="image/*"
                      placeholder="Ubah Banner"
                      ref="fileinput"
                    ></b-form-file>
                  </b-form-group>
                </v-col>

                
              </v-row>

              <v-row class="ml-2 mr-2">
                <v-col cols="6">
                  <p>Nama Rumah Sakit</p>
                  <v-text-field
                    v-model="params.hospitalName"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <p>Kode Rumah Sakit</p>
                  <v-text-field
                    v-model="params.hospitalCode"
                    :rules="rules"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-2 mr-2">
                <v-col cols="6">
                  <p>Deskripsi Rumah Sakit</p>
                  <v-text-field
                    v-model="params.hospitalDescription"
                    :rules="rules"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <p>Type Rumah Sakit</p>
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="params.hptHospitalTypeId"
                    :items="dataType"
                    hide-details
                    item-value="value"
                    @input="selecttype"
                  >
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <p>Harga Konsultasi Tanpa Janji</p>
                  <v-text-field
                    required
                    type="number"
                    outlined
                    v-model="params.consultationFee"
                    class="inputPrice"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-2 mr-2">
                <v-col cols="4">
                  <p>Kode Faktur</p>
                  <v-text-field
                    :rules="rules"
                    v-model="params.hospitalCode2"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <p>Email</p>
                  <v-text-field
                    v-model="params.email"
                    :rules="emailRules"
                    label=""
                    required
                    type="email"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <p>Negara</p>
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="negara"
                    :items="dataNegara"
                    hide-details
                    item-value="value"
                    @input="getProvince"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="ml-2 mr-2">
                <v-col cols="4">
                  <p>No Telp</p>
                  <v-text-field
                    v-model="params.phone"
                    :rules="rules"
                    required
                    type="number"
                    outlined
                    class="inputPrice"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <p>Provinsi</p>
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="provinsi"
                    :items="dataProvince"
                    hide-details
                    item-value="value"
                    @input="selectprov"
                  >
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <p>Kota</p>
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="kota"
                    :items="dataCity"
                    item-value="value"
                    item-text="text"
                    hide-details
                    @input="selectcity"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="ml-2 mr-2">
                <v-col cols="4">
                  <p>Kecamatan</p>
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    item-value="value"
                    item-text="text"
                    v-model="kecamatan"
                    :items="dataDistrict"
                    hide-details
                    @input="selectdistrict"
                  >
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <p>Kelurahan</p>
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="params.geoSubDistrictId"
                    :items="dataSubDistrict"
                    hide-details
                    item-value="value"
                    item-text="text"
                    @input="selectsubdistrict"
                  >
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <p>Kode Pos</p>
                  <v-text-field
                    v-model="params.postalCode"
                    :rules="rules"
                    required
                    type="number"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-2 mr-2">
                <v-col cols="8">
                  <p>Alamat</p>
                  <v-text-field
                    v-model="params.hospitalAddress"
                    :rules="rules"
                    required
                    type="text"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <p>Zona Waktu</p>
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    :items="timeZone"
                    hide-details
                    item-value="value"
                    item-text="text"
                    v-model="params.timeZoneId"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="ml-2 mr-2">
                <v-col cols="4">
                  <v-switch
                    v-model="params.withoutAppointmentPaidStatus"
                    dense
                    class="ml-3"
                    ><template v-slot:label>
                      <div>
                        <p class="mt-5 instructionType">
                          Konsultasi Tanpa Janji
                        </p>
                      </div>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="4">
                  <v-switch
                    v-model="params.withAppointmentPaidStatus"
                    dense
                    class="ml-3"
                    ><template v-slot:label>
                      <div>
                        <p class="mt-5 instructionType">
                          Konsultasi Dengan Janji
                        </p>
                      </div>
                    </template>
                  </v-switch></v-col
                >
                <v-col cols="4">
                  <v-switch
                    v-model="params.pharmacyPaidStatus"
                    dense
                    class="ml-3"
                    ><template v-slot:label>
                      <div>
                        <p class="mt-5 instructionType">
                          Tebus Resep
                        </p>
                      </div>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
              <v-row class="mr-2 ml-2">
                <v-col cols="8" class="">
                  <p>Jenis Api</p>
                  <v-radio-group
                    v-model="params.sirsIntegrationType"
                    mandatory
                    column
                    @change="pindahJudul"
                  >
                    <v-radio label="Quantum" value="Quantum">
                      <template v-slot:label>
                        <v-col cols="3"><p class="mt-4">Quantum</p></v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="appId"
                            v-if="params.sirsIntegrationType === 'Quantum'"
                            v-model="params.sirsIntegrationConfig.appId"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="secretKey"
                            v-if="params.sirsIntegrationType === 'Quantum'"
                            v-model="params.sirsIntegrationConfig.secretKey"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Url"
                            v-if="params.sirsIntegrationType === 'Quantum'"
                            v-model="params.sirsIntegrationConfig.url"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-col>
                      </template>
                    </v-radio>
                    <v-radio label="Ksi" value="Ksi">
                      <template v-slot:label>
                        <p class="mt-6">Ksi</p>
                        <v-row>
                          <v-text-field
                            label="appId"
                            v-if="params.sirsIntegrationType === 'Ksi'"
                            v-model="params.sirsIntegrationConfig.appId"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-row>
                        <v-spacer></v-spacer>
                        <v-row>
                          <v-text-field
                            label="secretKey"
                            v-if="params.sirsIntegrationType === 'Ksi'"
                            v-model="params.sirsIntegrationConfig.secretKey"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-row>
                        <v-row>
                          <v-text-field
                            label="url"
                            v-if="params.sirsIntegrationType === 'Ksi'"
                            v-model="params.sirsIntegrationConfig.url"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-row>
                      </template>
                    </v-radio>
                    <v-radio label="Tanpa Api" value="No"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6"> </v-col>
              </v-row>
            </v-form>
            <v-card-actions class="pb-4 mb-3">
              <v-spacer></v-spacer>
              <!-- {{params.sirsIntegrationType}} -->
              <v-btn medium outlined color="error" dark @click="closeDialog"
                >Batal</v-btn
              >
              <v-btn
                v-if="menuList[0].update === true"
                medium
                outlined
                color="success"
                dark
                @click="SaveEdit"
                >Simpan Edit</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dhapus" persistent max-width="1500">
          <v-card>
            <v-card-title
              >Yakin Hapus Rumah Sakit {{ namadelete }} ?</v-card-title
            >
            <v-card-actions class="pb-4 mb-3">
              <v-spacer></v-spacer>
              <v-btn medium outlined color="success" dark @click="hapus"
                >Hapus</v-btn
              >
              <v-btn medium outlined color="error" dark @click="closeDialog"
                >Batal</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
  import serviceHospital from "@/services/hospital_services";
  import servicegeo from "@/services/geo_services";
  import axios from "axios";
  export default {
    components: {
      // Popup
    },
    data: () => ({
      slot: [5, 10, 15, 20],
      pageNumber: 10,
      paginate: { id: "", name: "", page: 0, size: 10 },
      totalpage: [],
      pageCount: 0,
      page: 1,

      hptId: "",
      foto: null,
      menuList: {},
      login: {},
      role: ["Admin", "Ksi"],
      rs: ["Rs Medistra", "Rs Pondok Indah"],
      dialogEdit: false,
      dataRs: [],
      logoRs: null,
      dataProvince: [],
      dataCity: [],
      dataDistrict: [],
      dataSubDistrict: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || "E-mail must be valid",
      ],
      dataType: [],
      geoitem: [],
      dataNegara: [],
      provinsi: "",
      kota: "",
      kecamatan: "",
      kelurahan: "",
      negara: "",
      postalcode: "",
      namadelete: "",
      iddelete: "",
      hospital: [],
      dhapus: false,
      forsir: "",
      params: {
        hptHospitalId: "",
        hptHospitalTypeId: "",
        hospitalCode: "",
        hospitalCode2: "",
        sirsIntegrationType: "",
        hospitalName: "",
        registrationNumber: "",
        phone: "",
        email: "",
        viewOrder: 0,
        mainStatus: true,
        geoSubDistrictId: "",
        postalCode: "",
        hospitalAddress: "",
        hospitalDescription: "",
        sirsIntegrationConfig: {
          appId: "",
          secretKey: "",
          url: "",
        },
        consultationFee: "",
        consultationDiscountPercentage: 0,
        consultationDiscount: 0,
        withAppointmentPaidStatus: true,
        withoutAppointmentPaidStatus: true,
        pharmacyPaidStatus: true,
        timeZoneId: "WIB",
      },
      timeZone: [
        {
          text: "WIB",
          value: "WIB",
        },
        {
          text: "WITA",
          value: "WITA",
        },
        {
          text: "WIT",
          value: "WIT",
        },
      ],
      isactive: [
        {
          status: true,
          label: "Aktif",
        },
        {
          status: false,
          label: "Non Aktif",
        },
      ],
      staturs: [],
      userdata: [
        {
          nama: "Tofan",
          jenisrole: "Admin",
          role: "Admin Author",
          email: "t@gmail.com",
          rs: "Rs Medistra",
          status: "Aktif",
        },
        {
          nama: "Tofan",
          jenisrole: "Admin",
          role: "Admin Author",
          email: "t@gmail.com",
          rs: "Rs Medistra",
          status: "Aktif",
        },
        {
          nama: "Tofan",
          jenisrole: "Admin",
          role: "Admin Author",
          email: "t@gmail.com",
          rs: "Rs Medistra",
          status: "Aktif",
        },
      ],
      rules: [(v) => !!v || "Name is required"],
      sirstype: "",
      api: "",
      url: "",
      secretkey: "",
      search: "",
    }),

    mounted() {
      this.loginData();
      this.gethospital();
      this.getCountry();
      // this.getDetailProvince();
      this.getType();
      this.getCity();
      this.getDistrict();
    },
    watch: {},
    computed: {},
    methods: {
      upload(event) {
        console.log("VALUE " + JSON.stringify(event));
        // console.log(event.target);
        this.foto = event.target.files[0];
        // console.log(this.foto);
      },
      onSearch() {
        this.paginate.page = 0;
        // this.paginate.size = 100000000
        this.paginate.name = this.search;
        // console.log(this.paginate);
        this.gethospital(this.paginate);
      },
      choosePage(page) {
        console.log(page);
        this.paginate.page = page - 1;
        // console.log(this.paginate);
        this.gethospital(this.paginate);
      },
      selectedPage() {
        // console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.gethospital(this.paginate);
      },

      pindahJudul() {
        // console.log(this.params.sirsIntegrationType);
        if (this.params.sirsIntegrationType === this.sirstype) {
          //  this.params.sirsIntegrationType = this.sirstype
          this.params.sirsIntegrationConfig.appId = this.api;
          this.params.sirsIntegrationConfig.secretKey = this.secretkey;
          this.params.sirsIntegrationConfig.url = this.url;
        } else {
          this.params.sirsIntegrationConfig.appId = "";
          this.params.sirsIntegrationConfig.secretKey = "";
          this.params.sirsIntegrationConfig.url = "";
        }
      },
      loginData() {
        this.login = JSON.parse(window.localStorage.getItem("loginData"));
        // console.log("login list", this.login.features);
        this.menuList = this.login.features.filter(
          (item) => item.featureName === "Hospital Maintenance",
        );
        // console.log(this.menuList);
      },
      headers() {
        return [
          // {
          //   text: "No",
          //   align: "start",
          //   sortable: true,
          //   value: "index",
          //   class: "primary--text judul",
          // },
          {
            text: "",
            value: "foto",
            class: "primary--text",
          },
          {
            text: "Rumah Sakit",
            value: "nama",
            class: "primary--text",
          },
          // {
          //   text: "Provinsi",
          //   // value: "icdDescription",
          //   value: "province",
          //   class: "primary--text",
          // },
          {
            text: "Kota",
            value: "city",
            // value: "icdName",
            class: "primary--text",
          },
          {
            text: "Email",
            value: "email",
            // value: "icdName",
            class: "primary--text",
          },
          {
            text: "No Tlp",
            value: "tlp",
            // value: "icdName",
            class: "primary--text",
          },
          {
            text: "Status",
            value: "status",
            // value: "icdName",
            class: "primary--text",
          },
          {
            text: "Aksi",
            value: "aksi",
            class: "primary--text judul",
          },
        ];
      },
      edit(item) {
        console.log("item", item);
        (this.iddelete = item.id), this.getSirs(this.iddelete);
        // this.params.hptHospitalId = item.id;
        // this.logoRs = item.logoUrl;
        this.gethospitalByid(item.id);
        // this.params.hospitalName = item.nama;
        // this.params.hospitalCode = item.code;
        // this.params.hospitalCode2 = item.faktur;
        // this.params.hptHospitalTypeId = item.typeid;
        // this.params.hospitalDescription = item.desc;
        // this.params.email = item.email;
        // this.params.phone = item.tlp;
        // this.provinsi = item.provinceid;
        // this.getDetailProvince(item.provinceid);
        // this.getProvince()
        // this.kota = item.cityid;
        // this.getCity(this.provinsi);
        // this.getDistrict(this.kota);
        // this.kecamatan = item.districtid;
        // this.getSubDistrict(this.kecamatan);
        // this.params.geoSubDistrictId = item.subDistrictID;
        // this.params.postalCode = item.postalCode;
        // this.params.sirsIntegrationType = item.sirsIntegrationTypeCode;
        // this.params.hospitalAddress = item.address;
        // this.params.registrationNumber = item.noregis;
        // console.log(this.params);
        this.dialogEdit = true;
        this.hptId = item.hptHospitalId;
        // console.log(this.hptId);
      },
      dels(item) {
        // console.log(item);
        (this.iddelete = item.id), (this.namadelete = item.nama);
        // console.log(this.iddelete);
        this.dhapus = true;
      },
      closeDialog() {
        this.dialogEdit = false;
        this.dhapus = false;
      },
      getSirs(value) {
        serviceHospital.getSirsConfig(value).then((response) => {
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            console.log("sirs", response);
            this.sirstype = response.data.payload.sirsIntegrationType;
            this.api = response.data.payload.sirsIntegrationConfig.appId;
            this.secretkey =
              response.data.payload.sirsIntegrationConfig.secretKey;
            this.url = response.data.payload.sirsIntegrationConfig.url;
            this.params.sirsIntegrationConfig.appId =
              response.data.payload.sirsIntegrationConfig.appId;
            this.params.sirsIntegrationConfig.secretKey =
              response.data.payload.sirsIntegrationConfig.secretKey;
            this.params.sirsIntegrationConfig.url =
              response.data.payload.sirsIntegrationConfig.url;
          }
        });
      },
      getDetailProvince(value) {
        servicegeo.getProvinceByID(value).then((response) => {
          if (response.data.error !== "") {
            console.log(response.data.error);
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            // console.log("privnce", response);
            this.negara = response.data.payload.geoCountryId;
            servicegeo.getProvince(this.negara).then((response) => {
              // console.log("ini privince", response);
              this.master = response.data.payload.content;
              this.master.map((e) => {
                this.dataProvince.push({
                  value: e.geoProvinceId,
                  text: e.provinceName,
                });
              });
            });
          }
        });
      },
      gethospitalByid(value) {
        serviceHospital.getHospitalId(value).then((response) => {
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            console.log(response);
            this.hospital = response.data.payload;
            // console.log("hospital id", this.hospital);
            this.params.hptHospitalId = this.hospital.hptHospitalId;
            this.logoRs = this.hospital.logoUrl;
            this.params.hospitalName = this.hospital.hospitalName;
            this.params.hospitalCode = this.hospital.hospitalCode;
            this.params.hospitalCode2 = this.hospital.hospitalCode2;
            this.params.hptHospitalTypeId = this.hospital.hptHospitalTypeId;
            this.params.hospitalDescription = this.hospital.hospitalDescription;
            this.params.email = this.hospital.email;
            this.params.phone = this.hospital.phone;
            this.provinsi = this.hospital.geoProvinceId;
            this.getDetailProvince(this.hospital.geoProvinceId);
            // this.getProvince()
            this.kota = this.hospital.geoCityId;
            this.getCity(this.provinsi);
            this.getDistrict(this.kota);
            this.kecamatan = this.hospital.geoDistrictId;
            this.getSubDistrict(this.kecamatan);
            this.params.geoSubDistrictId = this.hospital.geoSubDistrictId;
            this.params.postalCode = this.hospital.postalCode;
            this.params.sirsIntegrationType = this.hospital.sirsIntegrationTypeCode;
            this.params.hospitalAddress = this.hospital.hospitalAddress;
            this.params.registrationNumber = this.hospital.registrationNumber;
            this.hptId = this.hospital.hptHospitalId;
            this.params.consultationFee = this.hospital.consultationFee;
            this.params.consultationDiscountPercentage = this.hospital.consultationDiscountPercentage;
            this.params.consultationDiscount = this.hospital.consultationDiscount;
            this.params.withAppointmentPaidStatus = this.hospital.withAppointmentPaidStatus;
            this.params.withoutAppointmentPaidStatus = this.hospital.withoutAppointmentPaidStatus;
            this.params.pharmacyPaidStatus = this.hospital.pharmacyPaidStatus;
            this.params.timeZoneId = this.hospital.timeZoneId;
          }
        });
      },
      gethospital(value) {
        value = this.paginate;
        serviceHospital.getHospital(value).then((response) => {
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            // console.log(response);
            this.dataRs = [];
            var hospital = response.data.payload.content;
            this.pageCount = response.data.payload.totalPages;
            this.totalpage = response.data.payload;
            // this.staturs.push(...this.isactive.filter((obj) => obj.status));
            hospital.map((e) => {
              this.dataRs.push({
                faktur: e.hospitalCode2,
                id: e.hptHospitalId,
                code: e.hospitalCode,
                nama: e.hospitalName,
                typeid: e.hptHospitalTypeId,
                desc: e.hospitalDescription,
                address: e.hospitalAddress,
                noregis: e.registrationNumber,
                provinceid: e.geoProvinceId,
                cityid: e.geoCityId,
                districtid: e.geoDistrictId,
                subDistrictID: e.geoSubDistrictId,
                postalCode: e.postalCode,
                tlp: e.phone,
                email: e.email,
                province: e.provinceName,
                sirsIntegrationTypeCode: e.sirsIntegrationTypeCode,
                city: e.cityName,
                active: e.active,
                logoUrl: e.logoUrl,
                hptHospitalId: e.hptHospitalId,
              });

              this.dataRs.forEach((e) => {
                if (e.active === true) {
                  e.status = "Aktif";
                } else {
                  e.status = "Non Aktif";
                }
              });
            });
          }
        });
      },
      getCountry() {
        servicegeo.getCountry().then((response) => {
          // console.log("ini country", response);
          var nl = response.data.payload.content;
          nl.map((e) => {
            this.dataNegara.push({
              value: e.geoCountryId,
              text: e.countryName,
            });
          });
        });
      },
      getDistrict(value) {
        // console.log(this.kota);
        servicegeo.getDistrict(value).then((response) => {
          console.log("ini city", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataDistrict.push({
              value: e.geoDistrictId,
              text: e.districtName,
            });
          });
        });
      },
      getProvince() {
        // var label = "INA";
        servicegeo.getProvince(this.negara).then((response) => {
          console.log("ini privince", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataProvince.push({
              value: e.geoProvinceId,
              text: e.provinceName,
            });
          });
        });
      },
      getCity(value) {
        console.log(this.dataProvince);
        console.log(this.provinsi);
        servicegeo.getCity(value).then((response) => {
          // console.log("ini city", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataCity.push({
              value: e.geoCityId,
              text: e.cityName,
            });
          });
        });
      },
      getSubDistrict() {
        // console.log();
        this.dataSubDistrict = [];
        servicegeo.getSubDistrict(this.kecamatan).then((response) => {
          console.log("ini city", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataSubDistrict.push({
              value: e.geoSubDistrictId,
              text: e.subDistrictName,
            });
          });
        });
      },
      selectprov() {
        // console.log(this.dataProvince);
        // console.log(this.provinsi);
        this.dataCity = [];
        servicegeo.getCity(this.provinsi).then((response) => {
          // console.log("ini city", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataCity.push({
              value: e.geoCityId,
              text: e.cityName,
            });
          });
        });
      },
      selecttype() {
        // console.log(this.params.hptHospitalTypeId);
      },
      selectcity() {
        // console.log(this.kota);
        this.dataDistrict = [];
        servicegeo.getDistrict(this.kota).then((response) => {
          // console.log("ini city", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataDistrict.push({
              value: e.geoDistrictId,
              text: e.districtName,
            });
          });
        });
      },
      selectdistrict() {
        // console.log(this.kecamatan);
        this.dataSubDistrict = [];
        servicegeo.getSubDistrict(this.kecamatan).then((response) => {
          // console.log("ini city", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataSubDistrict.push({
              value: e.geoSubDistrictId,
              text: e.subDistrictName,
            });
          });
        });
      },
      selectsubdistrict() {
        servicegeo
          .getSubDistrictByID(this.params.geoSubDistrictId)
          .then((response) => {
            // console.log(response);
            this.params.postalCode = response.data.payload.postalCode;
          });
      },

      getType() {
        serviceHospital.getHospitalTypeAll().then((response) => {
          // console.log("ini type", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataType.push({
              value: e.hptHospitalTypeId,
              text: e.hospitalTypeName,
              code: e.hospitalTypeCode,
            });
          });
        });
      },
      SaveEdit() {
        if (this.params.sirsIntegrationType === "No") {
          delete this.params.sirsIntegrationConfig["appId"];
          delete this.params.sirsIntegrationConfig["secretKey"];
          delete this.params.sirsIntegrationConfig["url"];
          this.params.sirsIntegrationConfig["mrnFormat"] = ".";
          // console.log(this.params);
        }
        console.log(this.params);
        let request = {
          id: this.iddelete,
          body: this.params,
        };
        serviceHospital
          .editHospital(request)
          .then((response) => {
            console.log(response);
            if (response.data.error !== "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
                message: "Edit Rumah Sakit Berhasil Di Simpan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              if (this.foto !== null) {
                let formData = new FormData();
                formData.append("profilePicture", this.foto);
                axios
                  .post(
                    `https://dev-api.klinisia.id/admin/storage/file/hospital-logo/${this.hptId}`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",

                        Authorization:
                          "Bearer " + window.localStorage.getItem("shortToken"),
                      },
                    },
                  )
                  .then((response) => {
                    console.log("foto sukses");
                    console.log(response);
                    this.dataRs = [];
                    this.gethospital();
                  })
                  .catch((err) => {
                    console.log("foto error", err);
                    // this.$toast.error(err, {
                    //   type: "error",
                    //   position: "top-right",
                    //   duration: 5000,
                    // });
                    console.log(this.foto);
                  });
              }
              console.log(response);
              if (this.foto === null) {
                this.dataRs = [];
                this.gethospital();
              }
              this.$refs.fileinput.reset();
              // this.dataRs = []
              this.closeDialog();
              // this.gethospital();
              this.dialogEdit = false;
              // window.location.reload();
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      },
      hapus() {
        serviceHospital.delHospital(this.iddelete).then((response) => {
          console.log("hapus rs", response);
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Data Rumah Sakit Berhasil Di Hapus",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.dhapus = false;
            window.location.reload();
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../scss/dashboard.scss";
  .cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
  .titleContent {
    font-weight: 500;
    line-height: 1rem !important;
  }
  .inputPrice input[type="number"] {
    -moz-appearance: textfield;
  }
  .inputPrice input::-webkit-outer-spin-button,
  .inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .background {
    background-color: #edf4fb;
  }
  .cardsearch {
    background-color: #ffffff;
    border-radius: 30px;
    width: 99%;
    margin-left: 2px;
  }
  .headerComponent {
    margin-left: 250px;
  }
  .datapasien {
    margin-left: 250px;
    margin-right: 120px;
  }
  .header {
    margin-left: 250px;
    /* margin-right: 250px; */
  }
  .poppuphead {
    text-align: center;
  }
  .v-card__title {
    font-size: 0.9rem !important;
    line-height: 1rem !important;
  }
  .search {
    margin-top: 15px;
    margin-left: 10px;
  }
  .detail {
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #2b92e4;
  }
  .image {
    width: 40px !important;
  }
  .nama {
    font-family: Nunito;
    font-size: 22px;
    text-align: left;
  }
  // .namaTable {
  //   font-family: Nunito;
  //   font-size: 6px !important;
  //   font-weight: bold;
  //   text-align: left;
  // }
  .icd-search {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #2b92e4;
  }
  .icd-text {
    font-family: Nunito;
    font-size: 16px;
    font-weight: bold;
  }

  .text {
    font-family: Nunito;
    font-size: 11px;
    font-weight: 600;
    height: 2px !important;
    width: 80px !important;
  }
  .instructionType {
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
  .tabletd {
    border: 1px solid rgba(68, 133, 253, 0.5);
    text-align: center;
  }

  .table {
    width: 100%;
  }
</style>
