<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-row class="cardsearch cardStyle">
        <v-col sm="12" md="4" lg="3" class="mt-5">
          <p class="icd-search">ICD CODE</p>
        </v-col>
        <v-col sm="12" md="6" lg="6" class="search">
          <v-text-field
            label="search"
            v-model="Search"
            append-icon="mdi-magnify"
            @keyup.enter="onSearch"
            outlined
            rounded
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-card class="cardStyle">
        <v-row>
          <v-col>
            <v-row class="">
              <v-col cols="2">
                <v-btn
                  v-if="menuList[0].create === true"
                  dark
                  color="success"
                  class="ml-3 mt-1"
                  small
                  @click="tambah"
                  ><v-icon dark x-small> mdi-plus </v-icon>Tambah ICD</v-btn
                >
              </v-col>
              <v-col cols="2" class="mb-6">
                <v-select
                  hide-details=""
                  @change="selectedPage()"
                  v-model="pageNumber"
                  :items="slot"
                  outlined
                  dense
                  class="text"
                ></v-select>
              </v-col>
            </v-row>

            <v-row justify="center" class="mt-1">
              <v-col>
                <v-data-table
                  ref="table"
                  :server-items-length="pageCount"
                  :headers="header"
                  :items="o"
                  :search="Search"
                  hide-default-footer
                  @update:items-per-page="itempage"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:item.aksi="{ item }">
                    <td>
                      <v-icon dark x-small class="mr-1"> mdi-pencil </v-icon>
                      <v-btn
                        v-if="menuList[0].update === true"
                        color="primary"
                        dark
                        class="mr-1"
                        x-small
                        @click.stop="edit(item)"
                      >
                        <v-icon dark x-small class="mr-1"> mdi-pencil </v-icon
                        >Ubah</v-btn
                      >
                    </td>
                    <td>
                      <v-btn
                        v-if="menuList[0].delete === true"
                        color="error"
                        x-small
                        dark
                        @click.stop="delIcd(item)"
                      >
                        <v-icon dark x-small class="mr-1"> mdi-delete </v-icon
                        >Hapus</v-btn
                      >
                    </td>
                  </template>
                  <!-- <template class="ma-0" v-slot:item.aksi2="{ item }">
                   
                  </template> -->
                </v-data-table>
              </v-col>
            </v-row>
            <!-- <v-row justify="center" class="mt-3">
              <v-btn class="" color="white" small @click="dirPaginate('prev')"
                ><v-icon>mdi-menu-left</v-icon></v-btn
              >
              <v-btn
                class="ml-3"
                color="white"
                small
                @click="dirPaginate('next')"
                ><v-icon>mdi-menu-right</v-icon></v-btn
              >
            </v-row> -->
            <v-pagination
              circle
              v-model="page"
              :length="icdPage.totalPages"
              :total-visible="7"
              @input="choosePage(page)"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <!-- Dialog Tambah -->
    <v-dialog v-model="isTambah" persistent max-width="830">
      <v-card>
        <v-card-title class="icd-search"><p>Tambah ICD Code</p></v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col lg="4" md="4" sm="12">
                <p class="icd-text">ICD Code</p>
                <v-text-field
                  v-model="addIcd.icdCode"
                  :rules="nameRules"
                  placeholder=""
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col lg="8" md="8" sm="12">
                <p class="icd-text">ICD Name</p>
                <v-text-field
                  v-model="addIcd.icdName"
                  :rules="nameRules"
                  placeholder=""
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col lg="12" md="12" sm="12">
                <p class="icd-text">ICD Description</p>
                <v-text-field
                  v-model="addIcd.icdDescription"
                  placeholder=""
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4 mb-3">
          <v-spacer></v-spacer>
          <v-btn medium outlined color="primary" dark @click="add"
            >Tambah</v-btn
          >
          <v-btn medium outlined color="error" dark @click="closeDialog"
            >Batal</v-btn
          >
          <!-- <v-btn color="#00CC6A" dark @click="finishChat">Akhiri</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Edit  -->
    <v-dialog v-model="toEdit" persistent max-width="830">
      <v-card>
        <v-card-title class="icd-search"
          ><p>Edit ICD Code {{ editIcd.icdCode }}</p></v-card-title
        >
        <v-card-text>
          <v-form>
            <v-row>
              <v-col lg="4" md="4" sm="12">
                <p class="icd-text">ICD Code</p>
                <v-text-field
                  v-model="editIcd.icdCode"
                  :rules="nameRules"
                  placeholder=""
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col lg="8" md="8" sm="12">
                <p class="icd-text">ICD Name</p>
                <v-text-field
                  v-model="editIcd.icdName"
                  :rules="nameRules"
                  placeholder=""
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col lg="12" md="12" sm="12">
                <p class="icd-text">ICD Description</p>
                <v-text-field
                  v-model="editIcd.icdDescription"
                  placeholder=""
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn medium outlined color="primary" dark @click="editConfirm"
            >Ubah</v-btn
          >
          <v-btn medium outlined color="error" dark @click="EditClose"
            >Batal</v-btn
          >
          <!-- <v-btn color="#00CC6A" dark @click="finishChat">Akhiri</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Hapus -->
    <v-dialog v-model="dels" persistent max-width="830">
      <v-card>
        <v-card-text>
          <p class="icd-text pt-5">
            Yakin Hapus ICD Dengan Code {{ kodeicd }} ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn medium outlined color="primary" dark @click="deleteConfirm"
            >Hapus</v-btn
          >
          <v-btn medium outlined color="error" dark @click="cancelDelete"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
  // import Popup from './popup.vue'
  // import axios from "axios";
  // import headers from '@/services/headers'
  // import PharmacyServices from "@/services/pharmacy_services";
  import IcdService from "@/services/ICD_service";

  export default {
    components: {
      // Popup
    },
    data: () => ({
      // page: 1,
      icdPage: [],
      menuList: {},
      login: {},
      isTambah: false,
      dels: false,
      Search: "",
      page: 1,
      toEdit: false,
      pageCount: 0,
      slot: [5, 10, 15, 20],
      kodeicd: "",
      pageNumber: 10,
      paginate: { name: "", page: 0, size: 10 },
      nameRules: [(v) => !!v || "Name is required"],
      icdList: [],
      addIcd: {
        cntMstIcdId: "string",
        icdCode: "",
        icdName: "",
        icdDescription: "",
      },
      editIcd: {
        cntMstIcdId: "",
        icdCode: "",
        icdName: "",
        icdDescription: "",
      },
      header: [
        {
          text: "No",
          align: "start",
          sortable: true,
          value: "index",
          class: "primary--text ",
        },
        {
          text: "ICD Code",
          value: "icdCode",
          class: "primary--text",
          sortable: false,
        },
        {
          text: "ICD Name",
          // value: "icdDescription",
          value: "icdName",
          class: "primary--text ",
        },
        // {
        //   text: "ICD Description",
        //   value: "icdDescription",
        //   // value: "icdName",
        //   class: "primary--text ",
        // },
        {
          text: "Aksi",
          value: "aksi",
          class: "primary--text ",
        },
        // {
        //   text: "",
        //   value: "aksi2",
        //   class: "primary--text ",
        // },
      ],
    }),
    mounted() {
      this.getIcdList();
      this.loginData();
    },
    watch: {
      //   Search() {
      //   // if (!value) {
      //   //   this.clearData();
      //   // }
      //   this.paginate.page = 0
      //   this.paginate.size = 100000
      //   this.getIcdList(this.paginate)
      // },
    },
    computed: {
      o() {
        return this.icdList.map((icdList, index) => ({
          ...icdList,
          index: index + this.icdPage.size * this.icdPage.number + 1,
        }));
      },
    },
    methods: {
      choosePage(page) {
        console.log(page);
        this.paginate.page = page - 1;
        this.getIcdList(this.paginate);
      },
      onSearch() {
        this.paginate.page = 0;
        // this.paginate.size = 100000000
        this.paginate.name = this.Search;
        console.log(this.paginate);
        this.getIcdList(this.paginate);
      },
      dirPaginate(val) {
        if (val === "next") {
          this.paginate.page++;
          this.getIcdList(this.paginate);
        }
        if (val === "prev") {
          if (this.paginate.page > 0) {
            this.paginate.page--;
            this.getIcdList(this.paginate);
          }
        }
      },
      selectedPage() {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.page = 1;
        this.paginate.page = 0;
        this.getIcdList(this.paginate);
      },
      loginData() {
        this.login = JSON.parse(window.localStorage.getItem("loginData"));
        console.log("login list", this.login.features);
        this.menuList = this.login.features.filter(
          (item) => item.featureName === "Master ICD Code",
        );
        console.log(this.menuList);
      },
      cancelDelete() {
        this.dels = false;
      },
      deleteConfirm() {
        IcdService.deleteICD(window.localStorage.getItem("IcdEdit"))
          .then((response) => {
            console.log(response);
            this.$toast.open({
              message: "Hapus Data Berhasil",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.dels = false;
            window.location.reload();
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      },
      delIcd(item) {
        window.localStorage.setItem("IcdEdit", item.cntMstIcdId);
        this.kodeicd = item.icdCode;
        this.dels = true;
      },
      editConfirm() {
        if (this.editIcd.icdCode === "") {
          this.$toast.open({
            message: "isi ICD code terlebih dahulu ",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else if (this.editIcd.icdName === "") {
          this.$toast.open({
            message: "isi ICD name terlebih dahulu ",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          console.log(this.editIcd);
          let request = {
            id: window.localStorage.getItem("IcdEdit"),
            body: this.editIcd,
          };
          IcdService.editICD(request)
            .then((response) => {
              if (response.data.error != "") {
                this.$toast.open({
                  message: response.data.error,
                  position: "top-right",
                  type: "error",
                  duration: 6000,
                });
                this.dialogEdit = false;
              } else {
                this.$toast.open({
                  message: "Edit ICD Code Berhasil ",
                  position: "top-right",
                  type: "success",
                  duration: 6000,
                });
                console.log(response);
                this.editIcd.icdCode = "";
                this.editIcd.icdName = "";
                this.editIcd.icdDescription = "";
                this.toEdit = false;
                window.location.reload();
              }
            })
            .catch((error) => {
              this.$toast.open({
                message: error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              console.log(error);
            });
        }
      },
      EditClose() {
        this.editIcd.icdCode = "";
        this.editIcd.icdName = "";
        this.editIcd.icdDescription = "";
        this.toEdit = false;
      },
      edit(item) {
        console.log(item);
        console.log(this.editIcd);
        this.editIcd.cntMstIcdId = item.cntMstIcdId;
        this.toEdit = true;
        window.localStorage.setItem("IcdEdit", item.cntMstIcdId);
        IcdService.detailICD(item.icdCode).then((response) => {
          console.log(response);
          this.editIcd.icdCode = response.data.payload.icdCode;
          this.editIcd.icdName = response.data.payload.icdName;
          this.editIcd.icdDescription = response.data.payload.icdDescription;
        });
      },
      add() {
        console.log(this.addIcd);
        if (this.addIcd.icdCode === "" || this.addIcd.icdName === "") {
          this.$toast.open({
            message: "ICD Code dan ICD Name Harus DI isi ",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          IcdService.addICD(this.addIcd)
            .then((response) => {
              console.log(response);
              if (response.data.error != "") {
                this.$toast.open({
                  message: response.data.error,
                  position: "top-right",
                  type: "error",
                  duration: 6000,
                });
              } else {
                this.$toast.open({
                  message: "Tambah Data ICD Berhasil",
                  position: "top-right",
                  type: "success",
                  duration: 6000,
                });
                this.addIcd.icdCode = "";
                this.addIcd.icdName = "";
                this.addIcd.icdDescription = "";
                window.location.reload();
                this.isTambah = false;
              }
            })
            .catch((error) => {
              this.$toast.open({
                message: error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              console.log(error);
            });
        }
        // axios.post(`https://dev-api.klinisia.id/admin/master/icd-code`, this.addIcd,
        // {
        //   headers: headers()
        //   })
        //   .then((response) =>{
        //     console.log(response)
        //   })
        //   .catch((error) =>{
        //     console.log(error)
        //   })
      },
      getIcdList(value) {
        value = this.paginate;
        IcdService.getICD(value).then((response) => {
          console.log(response);
          this.icdList = response.data.payload.content;
          this.icdPage = response.data.payload;
          this.pageCount = response.data.payload.totalPages;
        });
      },
      getdiagnostik() {
        IcdService.getdiagnostic().then((response) => {
          console.log(response);
        });
      },
      tambah() {
        this.isTambah = true;
      },
      closeDialog() {
        this.isTambah = false;
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../scss/dashboard.scss";
  .titleContent {
    font-weight: 500;
    line-height: 1rem !important;
  }
  .cardStyle {
    border-radius: 20px;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
  .background {
    background-color: #edf4fb;
  }
  .cardsearch {
    background-color: #ffffff;
    border-radius: 30px;
    width: 99%;
    margin-left: 2px;
  }
  .headerComponent {
    margin-left: 250px;
  }
  .datapasien {
    margin-left: 250px;
    margin-right: 120px;
  }
  .header {
    margin-left: 250px;
    /* margin-right: 250px; */
  }
  .poppuphead {
    text-align: center;
  }
  .v-card__title {
    font-size: 0.9rem !important;
    line-height: 1rem !important;
  }
  .instructionType {
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  .search {
    margin-top: 15px;
    margin-left: 10px;
  }
  .detail {
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #2b92e4;
  }
  .nama {
    font-family: Nunito;
    font-size: 22px;
    text-align: left;
  }
  .icd-search {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #2b92e4;
  }
  .icd-text {
    font-family: Nunito;
    font-size: 16px;
    font-weight: bold;
  }
  .text {
    font-family: Nunito;
    font-size: 11px;
    font-weight: 600;
    height: 2px !important;
    width: 80px !important;
  }
</style>
