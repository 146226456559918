import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://dev-api.klinisia.id/";

class AuthServices {
  async resetPassword(request) {
    return await axios.post(`${API_URL}auth/forget-pwd/by-email`, request, {
      headers: headers(),
    });
  }
  async getRole() {
    return await axios.get(
      `${API_URL}ksi/master/security/role/search?page=0&size=100&sortDirection=ASC&sortField=roleCode`,
      { headers: headers() },
    );
  }
  async getRolepage(param) {
    return await axios.get(
      `${API_URL}ksi/master/security/role/search?roleName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=ASC&sortField=roleCode`,
      { headers: headers() },
    );
  }
  async getFeature() {
    return await axios.get(
      `${API_URL}ksi/master/security/feature/search?page=0&size=100&sortDirection=ASC&sortField=featureCode`,
      { headers: headers() },
    );
  }
  async getSecRole(secRoleId) {
    return await axios.get(`${API_URL}ksi/master/security/role/${secRoleId}`, {
      headers: headers(),
    });
  }
  async addRole(data) {
    return await axios.post(`${API_URL}ksi/master/security/role`, data, {
      headers: headers(),
    });
  }
  async deleteRole(data) {
    return await axios.delete(`${API_URL}ksi/master/security/role/${data}`, {
      headers: headers(),
    });
  }
  async editRole(request) {
    return await axios.put(
      `${API_URL}ksi/master/security/role/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
}

export default new AuthServices();
