<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row>
          <v-col cols="auto" class="search">
            <h1 class="text-title">Total Antrian</h1>
          </v-col>
          <v-col cols="auto" class="search">
            <p class="bgTotalPatient mt-1">{{ totalPatientList }}</p>
          </v-col>
          <v-col offset="5">
            <p class="mt-3 text-title" offset="5">
              {{ queueDate }}, {{ queueDay }} {{ queueMonth }} {{ queueYear }}
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container>
      <v-card class="cardStyle">
        <v-row>
          <v-col cols="12">
            <template>
              <v-data-table
                :items-per-page="itemsPerPage"
                :page.sync="page"
                :headers="header"
                :items="indexof"
                :search="Search"
                @page-count="pageCount = $event"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.foto`]="{ item }">
                  <!-- <v-list-item-avatar height="45" width="45">
                    <img :src="item.profilePictureUrl" :alt="`${item.patientFullName}`" />
                  </v-list-item-avatar> -->
                  <v-avatar size="30">
                    <img
                      :src="
                        'https://dev-api.klinisia.id/storage/file/public/profile-picture/' +
                          item.secUserId
                      "
                      alt="foto"
                      class="img-card"
                    />
                  </v-avatar>
                  <!-- <a>{{item.profilePictureUrl}} +{{token}}</a> -->
                </template>
                <template v-slot:[`item.paymentDateByDate`]="{ item }">
                  <a>{{ item.paymentDate | orderDate }}</a>
                </template>
                <template v-slot:[`item.paymentDateByTime`]="{ item }">
                  <a>{{ item.paymentDate | orderTime }}</a>
                </template>
              </v-data-table>
            </template>
          </v-col>
        </v-row>
      </v-card>
      <!-- <vue-audio :file="file1" :autoPlay="statusPlay"/> -->
    </v-container>
    <v-pagination
      v-model="page"
      :length="pageCount"
      class="mt-5"
    ></v-pagination>
  </v-app>
</template>

<script>
  // import axios from "axios";
  import rekapAPI from "@/services/rekapPasien_service";
  import PharmacyServices from "@/services/pharmacy_services";
  import notif from "@/assets/pristine-609.mp3";
  // import VueAudio from "vue-audio";
  // import moment from "moment";

  export default {
    name: "rekapPasien",
    //  components: {
    //     "vue-audio": VueAudio,
    //   },
    data: () => ({
      file1: notif,
      statusPlay: true,
      token: window.localStorage.getItem("shortToken"),
      queueDate: "",
      queueDay: "",
      queueMonth: "",
      queueYear: "",
      today: "YYYY-MM-DD",
      radiologiCard: true,
      laboratCard: true,
      // date: null,
      enddate: null,
      detailPasien: true,
      popUp: false,
      menu: false,
      menu2: false,
      Search: "",
      page: 1,
      pageCount: 0,
      antri: 0,
      itemsPerPage: 10,
      slot: ["5", "10", "15"],
      newListDokter: [],

      header: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text ",
        },
        {
          text: "",
          value: "foto",
          class: "primary--text ",
        },
        {
          text: "Nama Pasien",
          value: "patientFullName",
          class: "primary--text ",
        },
        {
          text: "No. Handphone",
          value: "patientMobilePhone",
          class: "primary--text ",
        },
        {
          text: "Tgl Pembayaran",
          value: "paymentDateByDate",
          class: "primary--text ",
        },
        {
          text: "Jam Pembayaran",
          value: "paymentDateByTime",
          class: "primary--text ",
        },
      ],
      pasien2: [
        {
          id: "",
        },
      ],
      detailPenyakitUtama: [],
      detailPenyakitLain: [],
      doctor: [],
      doctorAssessment: [],
      doctorAllAssessment: [],
      doctordetail: [],
      pasien: [],
      pasienDetail: [],
      pasienDetailAssessment: [],
      detailObatMain: [],
      laboratorium: [],
      laboratoriumDetail: [],
      radiologiGroup: [],
      radiologi: [],
      lengkap: [],
      diagnosisDto: [],
      suggest: [],
      pengganti: [],
      namaDepan: [],
      namaPasien: [],
      namaBelakang: [],
      paging: { recentDate: "", lastDate: "" },
    }),
    mounted() {
      this.getLovs();
      this.getListPasien();
      if (window.localStorage.getItem("role") === "ADMIN") {
        this.detailPasien = false;
      }
      console.log(window.localStorage.getItem("indexGet"));
      var d = new Date();
      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

      var month = new Array();
      month[0] = "January";
      month[1] = "February";
      month[2] = "March";
      month[3] = "April";
      month[4] = "May";
      month[5] = "June";
      month[6] = "July";
      month[7] = "August";
      month[8] = "September";
      month[9] = "October";
      month[10] = "November";
      month[11] = "December";

      this.queueDate = weekday[d.getDay()];
      this.queueDay = d.getDate();
      this.queueMonth = month[d.getMonth()];
      this.queueYear = d.getFullYear();
    },
    methods: {
      getListPasien() {
        rekapAPI.getQueueList().then((response) => {
          console.log(response);
          console.log(response.data.payload);

          this.pasien2 = response.data.payload;
          console.log(this.pasien2.length);
          var data = this.pasien2.length;
          // console.log(data +1);
          // console.log("295", this.antri);
          if (this.antri > 0) {
            if (data + 1 > this.antri) {
              this.antri = this.pasien2.length + 1;
              // this.statusPlay = true;
              var audio = new Audio(this.file1);
              audio.play();
              // console.log("nyala");
            } else if (data + 1 < this.antri || data + 1 == this.antri) {
              //    var  sound = new Audio(this.file1)
              // sound.play()
              this.antri = this.pasien2.length + 1;
              // console.log("diam");
            }
          }
          if (this.antri == 0) {
            this.antri = this.pasien2.length + 1;
            // console.log(this.antri);
            // console.log("diam");
          }
          // rekapAPI.getSummary().then((response) => {
          //   console.log(response);
          // });
        });
        setTimeout(this.getListPasien, 5000);
      },
      getLovs() {
        PharmacyServices.getMasterLovsHospital().then((response) => {
          this.baseItemsObat = response.data.payload["medicine-type"];
          this.itemPeriod = response.data.payload["period-type"];
          this.baseItemHtu = response.data.payload["how-to-use-type"];
          this.baseItemUnit = response.data.payload["unit-type"];
          this.baseInstructionType = response.data.payload["instruction-type"];
        });
      },
      log() {
        console.log("====================================");
        console.log("asdads");
        console.log("====================================");
      },
      // date(){
      //   console.log(this.enddate);
      // },
      closing() {
        window.location.reload();
      },
      closing2() {
        (this.detailPenyakitUtama = []),
          (this.detailPenyakitLain = []),
          (this.doctor = []),
          (this.doctorAssessment = []),
          (this.doctorAllAssessment = []),
          (this.doctordetail = []),
          (this.pasien = []),
          (this.pasienDetail = []),
          (this.pasienDetailAssessment = []),
          (this.detailObatMain = []),
          (this.laboratorium = []),
          (this.laboratoriumDetail = []),
          (this.radiologiGroup = []),
          (this.radiologi = []),
          (this.lengkap = []),
          (this.suggest = []),
          (this.popUp = false);
      },
      dateDisabled(ymd, date) {
        const weekday = date.getDay();
        return weekday === 0 || weekday === 6;
      },
      open(item) {
        this.popUp = true;
        console.log(item.cntConsultationId);
        rekapAPI
          .getAdminPasienListDetail(item.cntConsultationId)
          .then((response) => {
            this.lengkap = response.data.payload.radiologyForm;
            console.log(response);
            this.diagnosisDto = response.data.payload.diagnosisDto;
            this.detailPenyakitUtama =
              response.data.payload.diagnosisDto.detailsMap.main;
            this.detailPenyakitLain =
              response.data.payload.diagnosisDto.detailsMap.other;
            this.doctor =
              response.data.payload.doctorAssessment.assessmentAnswers;
            this.doctordetail =
              response.data.payload.doctorAssessment.assessmentAnswers.optionSelectedIds;
            this.pasien = response.data.payload.patientAssessment;
            this.pasienDetail = response.data.payload.patient;
            this.doctorAssessment =
              response.data.payload.doctorAssessment.answerJson;
            this.namaPasien = response.data.payload.patient;
            console.log(this.namaDepan);
            const index = this.doctorAssessment.findIndex((e) =>
              e.optionSelecteds.map((s) => s.optionLabel).includes("Lainnya"),
            );
            // console.log(index);
            // console.log(this.doctorAssessment[index]);
            let data = [];
            for (
              let i = 0;
              i < this.doctorAssessment[index].optionSelecteds.length;
              i++
            ) {
              if (
                this.doctorAssessment[index].optionSelecteds[i].optionLabel ===
                "Lainnya"
              ) {
                data.push(this.doctorAssessment[index].optionNote);
              } else {
                data.push(
                  this.doctorAssessment[index].optionSelecteds[i].optionLabel,
                );
              }
            }
            // console.log(data);
            this.pengganti = data;

            this.doctorAssessment.forEach((obj) => {
              let data2 = [];
              for (let i = 0; i < obj.optionSelecteds.length; i++) {
                if (obj.optionSelecteds[i].optionLabel === "Lainnya") {
                  data2.push(obj.optionNote);
                } else {
                  data2.push(obj.optionSelecteds[i].optionLabel);
                }
              }
              // console.log(data2);
              obj.optionSelecteds = data2;
            });
            // console.log(this.doctorAssessment.optionSelecteds)
            console.log(this.doctorAssessment);
            this.doctorAllAssessment = response.data.payload.doctorAssessment;
            // console.log(this.doctorAllAssessment.mainComplaint);

            this.laboratoriumDetail = response.data.payload.laboratoryForm;
            if (this.laboratoriumDetail === null) {
              this.laboratCard = false;
            }
            this.radiologi = response.data.payload.radiologyForm;
            if (this.radiologi === null) {
              this.radiologiCard = false;
            }
            console.log(this.radiologi);
            //  this.detailObatMain=response.data.payload.prescriptionAll.mainItems
            this.detailObatMain = response.data.payload.prescriptionAll;
            this.suggest = response.data.payload.suggestion;
            console.log(this.suggest);
            // this.laboratorium=response.data.payload.laboratoryForm.groups
            // console.log(this.laboratorium);

            // this.radiologiGroup=response.data.payload.radiologyForm.groups
            this.pasienDetailAssessment =
              response.data.payload.patientAssessment.assessmentAnswers;
            if (this.detailObatMain !== null) {
              this.detailObatMain.mainItems.forEach((obj) => {
                obj.howToUseType = this.baseItemHtu.filter((item) =>
                  obj.howToUseType.includes(item.value),
                );
                console.log(obj.howToUseType);

                obj.periodType = this.itemPeriod.filter((item) =>
                  obj.periodType.includes(item.value),
                );
              });
            }
            //   console.log(this.detailObatMain);
            // console.log(this.detailPenyakitUtama);
          });
      },
    },
    watch: {
      playMusik() {
        var sound = new Audio(notif);
        sound.play();
      },
      // itEnds(){
      //   return (this.enddate , console.log(this.enddate))
      // },
    },
    computed: {
      totalPatientList() {
        return this.pasien2.length;
      },
      itStart() {
        return (
          this.paging.recentDate,
          console.log(this.paging.recentDate),
          this.getListPasien(this.paging)
        );
      },
      itEnds() {
        // this.paging.endDate = this.enddate

        return (
          this.paging.lastDate,
          console.log(this.paging.lastDate),
          this.getListPasien(this.paging)
        );

        // return this.log()
      },
      indexof() {
        return this.pasien2.map((pasien2, index) => ({
          ...pasien2,
          index: index + 1,
        }));
      },
      date(val) {
        return console.log(val);
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../scss/dashboard.scss";
  .bgTotalPatient {
    background: #a0cee8;
    font-size: 16px;
    width: 40px;
    color: #073059;
    text-align: center;
    border-radius: 50px;
  }
  .background {
    background-color: #edf4fb;
  }
  .text-title {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 600;
  }
  .instructionType {
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  .cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
</style>
