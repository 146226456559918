import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://dev-api.klinisia.id/admin/pharmacy/master/";
const API_URL2 = "https://dev-api.klinisia.id/pharmacy/master/";

class PharmacyService {
  async getMasterSearchMedice(query) {
    return await axios.get(`${API_URL}search-medicine?q=${query}`, {
      headers: headers(),
    });
  }
  async getMasterLovs() {
    return await axios.get(API_URL + "lovs", { headers: headers() });
  }
  async getMasterLovsHospital() {
    return await axios.get(API_URL2 + "lovs", { headers: headers() });
  }
}

export default new PharmacyService();
