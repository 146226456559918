<template>
  <v-app class="background">
    <b-container>
      <b-form>
        <b-card class="content">
          <b-card-title class="title">
            <b-row>
              <b-col>
                <h1 class="text-title">Edit Profil Dokter</h1>
              </b-col>
            </b-row>
          </b-card-title>

          <b-card-text>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-row>
                <b-col lg="3" md="6" sm="12">
                  <v-avatar class="mt-2" rounded size="200">
                    <img :src="ava" alt="foto" />
                  </v-avatar>
                </b-col>

                <b-col
                  lg="3"
                  md="6"
                  sm="12"
                  align-self="center"
                  style="margin-top: 80px"
                >
                  <p class="text-spesialis">Kategori</p>
                  <!-- <b-form-radio
                    v-model="newListDokter.docMstMedicSpecializationId"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="GENERAL"
                  >Dokter Umum</b-form-radio>-->
                  <v-row>
                    <v-col cols="auto">
                      <b-form-radio
                        v-model="radios"
                        :aria-describedby="ariaDescribedby"
                        name="some-radios"
                        value="GENERAL"
                        >Dokter Umum</b-form-radio
                      >
                    </v-col>
                    <v-col cols="auto">
                      <b-form-radio
                        v-model="radios"
                        :aria-describedby="ariaDescribedby"
                        name="some-radios"
                        value="spesialis"
                        >Dokter Spesialis</b-form-radio
                      >
                    </v-col>
                  </v-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="3" style="margin-top: 15px">
                  <b-form-group class="text-label" label="Ganti Foto Dokter">
                    <b-form-file
                      type="file"
                      @change="uploadFoto($event)"
                    ></b-form-file>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12" sm="12">
                  <b-form-group class="text-label" label="Nama Lengkap *">
                    <b-form-input
                      type="text"
                      v-model="newListDokter.fullName"
                      placeholder="Masukkan Nama Lengkap"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Tanggal Lahir (DD-MM-YY) *"
                  >
                    <b-form-input
                      v-model="newListDokter.birthDate"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>-->
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Biaya Konsultasi / 30 menit * "
                  >
                    <b-input-group
                      prepend="Rp"
                      label="Biaya Konsultasi / 30 menit * "
                    >
                      <b-form-input
                        id="biaya"
                        type="number"
                        v-model="newListDokter.consultationFee"
                        placeholder
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
               
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Harga Coret"
                  >
                    <b-input-group
                      prepend="Rp"
                      label="Harga Coret"
                    >
                      <b-form-input
                        id="consultationSpecialFee"
                        type="number"
                        v-model="newListDokter.consultationSpecialFee"
                        placeholder
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                
              </b-row>

              <b-row>
                <b-col>
                    <b-form-group
                      class="text-label"
                      label="Tanggal Lahir (DD-MM-YY) *"
                    >
                      <b-form-input
                        v-model="newListDokter.birthDate"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="Email *">
                    <b-form-input
                      v-model="newListDokter.email"
                      placeholder="masukkan email dokter"
                      type="email"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
               
              </b-row>
              <b-row>
               <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="No. Handphone">
                    <b-form-input
                      v-model="newListDokter.mobilePhone"
                      placeholder="masukkan email dokter"
                      type="number"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Hospital Employee Number *"
                  >
                    <b-form-input
                      v-model="newListDokter.hospitalEmployeeNumber"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group class="text-label" label="SIP Number *">
                    <b-form-input
                      v-model="newListDokter.sipNumber"
                      maxlength="60"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
             
            </b-form-group>
          </b-card-text>
          <v-card v-if="radios === 'spesialis'">
            <v-row>
              <v-col cols="12" class="ml-4">
                <span
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Kategori</span
                >
              </v-col>
              <v-col cols="4" class="ml-4">
                <v-select
                  v-model="newListDokter.docMstMedicSpecializationId"
                  :items="listspesialisasi"
                  item-value="value"
                  item-text="text"
                  outlined
                  dense
                  label="Spesialisasi Dokter *"
                  @input="selectdoctorspc(value)"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="listSubSpesialisasi"
                  v-model="newListDokter.docMstMedicSubSpcId"
                  item-value="value"
                  item-text="text"
                  outlined
                  dense
                  label="Sub Spesialisasi Dokter"
                ></v-select>
              </v-col>
            </v-row>

            <!-- pengalaman -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Pengalaman</span
                >
              </v-col>
              <v-row>
                <v-col cols="10">
                  <p class="ml-9 namaJudul">Tambah Pengalaman Baru</p>
                  <v-row class="ml-2">
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        outlined
                        :items="years"
                        item-value="year"
                        v-model="experienceStartYear"
                      ></v-select>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        item-value="year"
                        outlined
                        v-model="experienceEndYear"
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        placeholder="Keterangan"
                        outlined
                        dense
                        v-model="experienceName"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="mt-4">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-9"
                    fab
                    dark
                    color="indigo"
                    x-small
                    @click="addPengalaman"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="10">
                  <p
                    class="ml-9 namaJudul"
                    v-if="newListDokter.experiences.length > 0"
                  >
                    Data Pengalaman
                  </p>
                  <v-row
                    class="ml-2"
                    v-for="(pengalaman, index) in newListDokter.experiences"
                    :key="index"
                  >
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        outlined
                        :items="years"
                        item-value="year"
                        v-model="pengalaman.experienceStartYear"
                      ></v-select>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        value="year"
                        outlined
                        v-model="pengalaman.experienceEndYear"
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        placeholder="Keterangan"
                        outlined
                        dense
                        v-model="pengalaman.experienceName"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>

            <!-- pendidilkan -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Pendidikan</span
                >
              </v-col>
              <v-row>
                <v-col cols="10">
                  <p class="ml-9 namaJudul">Tambah Pendidikan Baru</p>
                  <v-row class="ml-2">
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        v-model="educationStartYear"
                        item-value="year"
                        outlined
                      ></v-select>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        v-model="educationEndYear"
                        item-value="year"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        v-model="educationName"
                        placeholder="Keterangan"
                        outlined
                        counter
                        maxlength="500"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="mt-4">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-8"
                    fab
                    dark
                    color="indigo"
                    x-small
                    @click="addPendidikan"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="10">
                  <p
                    class="ml-9 namaJudul"
                    v-if="newListDokter.educations.length > 0"
                  >
                    Data Pendidikan
                  </p>
                  <v-row
                    v-for="(edu, i) in newListDokter.educations"
                    :key="i"
                    class="ml-2"
                  >
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        v-model="edu.educationStartYear"
                        value="year"
                        outlined
                      ></v-select>
                    </v-col>
                    <p class="mt-4">Ke</p>
                    <v-col cols="2">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        v-model="edu.educationEndYear"
                        value="year"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        v-model="edu.educationName"
                        placeholder="Keterangan"
                        outlined
                        counter
                        maxlength="500"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>

            <!-- penghargaan -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Penghargaan</span
                >
              </v-col>
              <v-row>
                <v-col cols="10">
                  <p class="ml-9 namaJudul">Tambah Penghargaan Baru</p>
                  <v-row class="ml-1">
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        item-value="year"
                        v-model="awardYear"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        v-model="awardName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="mt-4">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-8"
                    fab
                    dark
                    color="indigo"
                    x-small
                    @click="addPenghargaan"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="10">
                  <p
                    class="ml-9 namaJudul"
                    v-if="newListDokter.awards.length > 0"
                  >
                    Data Penghargaan
                  </p>
                  <v-row
                    v-for="(penghargaan, i) in newListDokter.awards"
                    :key="i"
                    class="ml-1"
                  >
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        :items="years"
                        item-value="year"
                        v-model="penghargaan.awardYear"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        v-model="penghargaan.awardName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>

            <!-- pencapaian -->
            <v-row>
              <v-col cols="12">
                <span
                  class="col-md-6"
                  style="
                    font-family: Nunito;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                  "
                  >Pencapaian</span
                >
              </v-col>
              <v-row>
                <v-col cols="10">
                  <p class="ml-9 namaJudul">Tambah Pencapaian Baru</p>
                  <v-row class="ml-1">
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        item-value="year"
                        :items="years"
                        v-model="achievementYear"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        v-model="achievementName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-12"
                    fab
                    dark
                    color="indigo"
                    x-small
                    @click="addPencapaian"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="10">
                  <p
                    class="ml-9 namaJudul"
                    v-if="newListDokter.achievements.length > 0"
                  >
                    Data Pencapaian
                  </p>
                  <v-row
                    class="ml-1"
                    v-for="(pencapaian, i) in newListDokter.achievements"
                    :key="i"
                  >
                    <v-col cols="2" class="ml-3">
                      <v-select
                        dense
                        placeholder="Tahun"
                        item-value="year"
                        :items="years"
                        v-model="pencapaian.achievementYear"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col cols="2"></v-col>
                    <v-col cols="7" class="ml-5">
                      <v-text-field
                        v-model="pencapaian.achievementName"
                        placeholder="Keterangan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-card>
          <v-row>
            <v-col offset-md="9">
              <v-btn
                tile
                class="btn-edit"
                rounded
                outlined
                color="primary"
                @click="cancel"
                style="margin-right: 10px"
                >Batal</v-btn
              >
              <v-btn
                class="btn-edit"
                :disabled="confirmDisabled"
                @click="submited"
                color="success"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </b-card>
      </b-form>
    </b-container>
  </v-app>
</template>

<script>
  import scheduleService from "@/services/DoctorSchedule_service";
  import { BFormInput } from "bootstrap-vue";
  import "flatpickr/dist/flatpickr.css";

  import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
  import axios from "axios";
  import DoctorServices from "@/services/doctor_services";

  export default {
    name: "lihat",
    upload: "",

    // props:['data'],
    components: {
      BFormInput,
      // flatPicker
      // datePicker
    },
    data: () => ({
      confirmDisabled: false,
      listSubSpesialisasi: [],
      listspesialisasi: [],
      radios: "",
      ava: null,
      foto: null,
      newListDokter: {
        docDoctorHospitalId: "",
        docDoctorId: "string",
        hptHospitalId: "string",
        docMstMedicSpecializationId: "",
        sipNumber: "",
        secUserId: "",
        idNumber: "string",
        taxNumber: "",
        passportNumber: "string",
        fullName: "",
        gender: "male",
        birthDate: "2021-01-28",
        mobilePhone: "",
        email: "",
        hospitalEmployeeNumber: "",
        geoSubDistrictId: "string",
        postalCode: "string",
        address: "string",
        ratingStar: 0,
        awards: [],
        achievements: [],
        educations: [],
        experiences: [],

        profilePictureUrl: "string",
      },
      time: "20:30",
      awardInstitutionName: "",
      awardYear: 0,
      awardName: "",
      awardDescription: "",

      achievementInstitutionName: "",
      achievementYear: 0,
      achievementName: "",
      achievementDescription: "",

      educationInstitutionName: "",
      educationStartYear: 0,
      educationEndYear: 0,
      educationName: "",
      educationDescription: "",

      experienceInstitutionName: "",
      experienceStartYear: 0,
      experienceEndYear: 0,
      experienceName: "",
      experienceDescription: "",

      user2: {
        doctor: {
          docDoctorHospitalId: "string",
          docDoctorId: "string",
          hptHospitalId: "MEDISTRA",
          docDoctorMedicalSpecialistId: "UMUM",
          // docMstMedicSubSpcId:"",
          sipNumber: "123456987",
          secUserId: "string",
          idNumber: "string",
          taxNumber: "",
          passportNumber: "string",
          fullName: "qertyui",
          gender: "male",
          hospitalEmployeeNumber: "",
          birthDate: "2021-01-22T07:58:52.097Z",
          mobilePhone: "62812876",
          email: "q1@gmail.com",
          geoSubDistrictId: "string",
          postalCode: "string",
          address: "string",
          qualifications: null,
          dateSchedule: null,
          daySchedule: null,
          awards: [],
          achievements: [],
          educations: [],
          experiences: [],
          profilePictureUrl: "string",
        },
        password: "string",
        rePassword: "string",
      },
      data: [],
    }),
    mounted() {
      this.getData();
      this.spesial();

      this.selectdoctorspc();
      console.log(this.this.newListDokter.docDoctorHospitalId);
      console.log(this.radios);
    },
    methods: {
      check() {
        console.log(this.newListDokter);
      },
      nyoba() {
        console.log(this.newListDokter);
        console.log(this.radios);
        // this.confirmDisabled = true
      },
      selectdoctorspc() {
        this.listSubSpesialisasi = [];
        this.newListDokter.docMstMedicSubSpcId = "";
        console.log(this.newListDokter.docMstMedicSpecializationId);
        axios
          .get(
            `https://dev-api.klinisia.id/general/doctor/medic-specialization/${this.newListDokter.docMstMedicSpecializationId}/medic-sub-spc`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + window.localStorage.getItem("shortToken"),
              },
            },
          )
          .then((res) => {
            console.log(res);
            var data = res.data.payload;
            data.map((e) => {
              this.listSubSpesialisasi.push({
                value: e.docMstMedicSubSpcId,
                text: e.subSpcName,
              });
            });
          });
      },
      spesial() {
        scheduleService.getSPecialization().then((res) => {
          console.log(res);
          var data = res.data.payload;
          var realdata = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].docMstMedicSpecializationId !== "GENERAL") {
              realdata.push(data[i]);
            }
          }
          realdata.map((e) => {
            this.listspesialisasi.push({
              value: e.docMstMedicSpecializationId,
              text: e.specializationName,
            });
          });
          console.log(this.listspesialisasi);
        });
      },
      addPencapaian() {
        if (this.achievementYear === 0 || this.achievementName === "") {
          this.$toast.error("Lengkapi Data Pencapaian Terlebih Dahulu", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        } else {
          this.newListDokter.achievements.push({
            docDoctorHptAchievementId: "",
            achievementInstitutionName: "",
            achievementYear: this.achievementYear,
            achievementName: this.achievementName,
            achievementDescription: this.achievementName,
          });
          this.achievementYear = 0;
          this.achievementName = "";
          this.newPencapaian = true;
        }
        console.log(this.newListDokter.achievements);
      },
      addPenghargaan() {
        if (this.awardYear === 0 || this.awardName === "") {
          this.$toast.error("Lengkapi Data Penghargaan Terlebih Dahulu", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        } else {
          this.newListDokter.awards.push({
            docDoctorHptAwardId: "",
            // docDoctorHospitalId: "",
            awardInstitutionName: "",
            awardYear: this.awardYear,
            awardName: this.awardName,
            awardDescription: this.awardName,
          });
          this.awardYear = 0;
          this.awardName = "";
          this.newPenghargaan = true;
        }
        console.log(this.newListDokter.awards);
      },
      addPendidikan() {
        if (
          this.educationStartYear === 0 ||
          this.educationEndYear === 0 ||
          this.educationName === ""
        ) {
          this.$toast.error("Lengkapi Data Pendidikan Terlebih Dahulu", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        } else {
          this.newListDokter.educations.push({
            docDoctorHptEducationId: "",
            // docDoctorHospitalId: "",
            educationInstitutionName: "",
            educationStartYear: this.educationStartYear,
            educationEndYear: this.educationEndYear,
            educationName: this.educationName,
            educationDescription: this.educationName,
          });

          this.educationStartYear = 0;
          this.educationEndYear = 0;
          this.educationName = "";
          this.newPendidikan = true;
        }
        console.log(this.newListDokter.educations);
      },
      addPengalaman() {
        if (
          this.experienceStartYear === 0 ||
          this.experienceEndYear === 0 ||
          this.experienceName === ""
        ) {
          this.$toast.error("Lengkapi Data Pengalaman Terlebih Dahulu", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        } else {
          // this.experienceShow = true;
          this.newListDokter.experiences.push({
            experienceInstitutionName: "",
            experienceStartYear: this.experienceStartYear,
            experienceEndYear: this.experienceEndYear,
            experienceName: this.experienceName,
            experienceDescription: "",
          });
          this.experienceStartYear = 0;
          this.experienceEndYear = 0;
          this.experienceName = "";
        }

        console.log(this.newListDokter.experiences);
      },
      getData() {
        let id = window.localStorage.getItem("docDoctorHospitalId");
        DoctorServices.getDoctorById(id)
          .then((response) => {
            console.log(response);
            this.newListDokter = response.data.payload;
            axios
              .get(
                `https://dev-api.klinisia.id/general/doctor/medic-specialization/${this.newListDokter.docMstMedicSpecializationId}/medic-sub-spc`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + window.localStorage.getItem("shortToken"),
                  },
                },
              )
              .then((res) => {
                console.log(res);
                var data = res.data.payload;
                data.map((e) => {
                  this.listSubSpesialisasi.push({
                    value: e.docMstMedicSubSpcId,
                    text: e.subSpcName,
                  });
                });
              });
            console.log(this.newListDokter);
            // if (this.newListDokter.educations.length === 0) {
            //   this.newListDokter.educations.push({
            //     educationInstitutionName: "",
            //     educationStartYear: 0,
            //     educationEndYear: 0,
            //     educationName: "",
            //     educationDescription: "",
            //   });
            // }
            // if (this.newListDokter.experiences.length === 0) {
            //   this.newListDokter.experiences.push({
            //     experienceInstitutionName: "",
            //     experienceStartYear: 0,
            //     experienceEndYear: 0,
            //     experienceName: "",
            //     experienceDescription: "",
            //   });
            // }
            // if (this.newListDokter.achievements.length === 0) {
            //   this.newListDokter.achievements.push({
            //     achievementInstitutionName: "",
            //     achievementYear: 0,
            //     achievementName: "",
            //     achievementDescription: "",
            //   });
            // }
            // if (this.newListDokter.awards.length === 0) {
            //   this.newListDokter.awards.push({
            //     awardInstitutionName: "",
            //     awardYear: 0,
            //     awardName: "",
            //     awardDescription: "",
            //   });
            // }
            // this.ava =
            //   this.$ppUrl +
            //   this.newListDokter.secUserId +
            //   "/?token=" +
            //   window.localStorage.getItem("shortToken");
            this.ava = this.newListDokter.profilePictureUrl;
            if (this.newListDokter.docMstMedicSpecializationId === "GENERAL") {
              this.radios = "GENERAL";
              console.log("ini general");
              console.log(this.this.newListDokter.docDoctorHospitalId);
              console.log(this.radios);
            } else {
              this.radios = "spesialis";
              console.log("bukan general");
              console.log("bukan ini");
              console.log(
                "bukan ini",
                this.newListDokter.docMstMedicSpecializationId,
              );
              console.log(this.radios);
            }
          })
          .catch((err) => console.log(err));
      },
      uploadFoto(event) {
        console.log("VALUE " + JSON.stringify(event));
        this.foto = event.target.files[0];

        // console.log('VALUE FOTO ' + this.foto);
      },
      submited() {
        this.confirmDisabled = true;

        if (this.newListDokter.consultationSpecialFee === "") {
          this.newListDokter.consultationSpecialFee = 0
        }

        if (this.radios === "GENERAL") {
          this.newListDokter.docMstMedicSpecializationId = "GENERAL";
          console.log("ini general");
        }
        console.log(JSON.stringify(this.newListDokter));
        if (this.newListDokter.fullName === "") {
          alert("nama doktor tidak boleh kosong");
        } else if (this.newListDokter.sipNumber === "") {
          alert("sip tidak boleh kosong");
        
        } else if (parseFloat(this.newListDokter.consultationSpecialFee) != 0 && parseFloat(this.newListDokter.consultationSpecialFee) < parseFloat(this.newListDokter.consultationFee)) {
            this.$toast.error("Harga coret` tidak boleh kurang dari `biaya konsultasi", {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
             this.confirmDisabled = false;

        } else {
          let formData = new FormData();
          formData.append("profilePicture", this.foto);
          var del = this.newListDokter.docDoctorHospitalId;
          axios
            .post(
              ` https://dev-api.klinisia.id/admin/storage/file/doctor-pp/${del}`,
              formData,
              {
                headers: {
                  // 'Access-Control-Allow-Origin': '*',
                  "Content-Type": "multipart/form-data",

                  Authorization:
                    "Bearer " + window.localStorage.getItem("shortToken"),
                },
              },
            )
            .then((response) => {
              console.log("foto sukses");
              console.log(response);
              console.log(
                ` https://dev-api.klinisia.id/admin/storage/file/doctor-pp/${del}`,
              );
              this.$router.push("/listDokter");
            })
            .catch((err) => {
              console.log("foto error");
              console.log(err);
              console.log(this.foto);
              this.confirmDisabled = false;
            });

          axios
            .put(
              ` https://dev-api.klinisia.id/admin/doctor/edit/${del}`,
              this.newListDokter,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + window.localStorage.getItem("shortToken"),
                },
              },
            )
            .then((response) => {
              console.log(response);
              if (response.data.error !== "") {
                this.$toast.open({
                  message: response.data.error,
                  position: "top-right",
                  type: "error",
                  duration: 6000,
                });
              } else {
                this.$toast.success("Data Dokter Berhasil Diubah", {
                  type: "success",
                  position: "top-right",
                  duration: 5000,
                });
                this.$router.push("/listDokter");
                console.log(response);
              }
            })
            .catch((err) => {
              console.log("PUT error");
              this.$toast.error(err, {
                type: "error",
                position: "top-right",
                duration: 5000,
              });
            });
        }
      },
      cancel() {
        this.$router.push("/listDokter");
      },
    },
    //     dateDisabled(ymd, date){
    //         const weekday = date.getDay()
    //         return weekday === 0 || weekday === 6
    //     }
    //   },
    computed: {
      passwordcheck() {
        return (
          this.user.Confirm.length > 0 &&
          this.user.Confirm === this.user.Password
        );
      },
      PhotoRule() {
        return this.user.Foto < 2000;
      },
      years() {
        const year = new Date().getFullYear();
        return Array.from(
          { length: year - 1950 },
          (value, index) => 1951 + index,
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../scss/dashboard.scss";
  .text-title {
    font-family: Nunito;
    font-size: 18px;
    font-weight: bold;
  }
  .namaJudul {
    font-family: Nunito;
    font-size: 13px;
    font-weight: bold;
    color: #2b92e4;
  }
</style>
