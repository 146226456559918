import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://dev-api.klinisia.id/";

class Doctor {
  async getShippingList(request) {
    return await axios.get(
      `${API_URL}admin/consultation/prescription/delivery/list?search=${request.name}&page=${request.page}&size=${request.size}&sortDirection=DESC&sortField=phrConfirmReqDatetime`,
      { headers: headers() },
    );
  }

  async updateConfirm(request) {
    return await axios.post(
      `${API_URL}admin/consultation/prescription/delivery/update-confirm/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async deliveredConfirm(request) {
    return await axios.post(
      `${API_URL}admin/consultation/prescription/delivery/update-delivered/${request.id}`,
      request.id,
      { headers: headers() },
    );
  }
  async sendConfirm(request) {
    return await axios.post(
      `${API_URL}admin/consultation/prescription/delivery/update-send/${request.id}`,
      request.id,
      { headers: headers() },
    );
  }
  async finishConfirm(request) {
    return await axios.post(
      `${API_URL}admin/consultation/prescription/delivery/update-finished/${request.id}`,
      request.id,
      { headers: headers() },
    );
  }
  async getAddress() {
    return await axios.get(`${API_URL}general/hospital/my-hospital`, {
      headers: headers(),
    });
  }
}

export default new Doctor();
