import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://dev-api.klinisia.id/";

class Pasien {
  async getPasienList(request) {
    return await axios.get(
      `${API_URL}admin/consultation/list-simple?startDate=${request.recentDate}&endDate=${request.lastDate}&search=${request.name}&page=${request.page}&size=${request.size}&sortDirection=DESC&sortField=consultationStartDatetime`,
      { headers: headers() },
    );
  }
  async getPasienListDetail(id) {
    return await axios.get(`${API_URL}admin/consultation/detail/${id}`, {
      headers: headers(),
    });
  }
  //  async getAdminHospitalList() {
  //     return await axios.get(`${API_URL}admin-hospital/consultation/list?page=0&size=10000000&sortDirection=DESC&sortField=consultationStartDatetime`, { headers: headers() })
  // }
  async getAdminHospitalList(request) {
    return await axios.get(
      `${API_URL}admin-hospital/consultation/list-simple?startDate=${request.recentDate}&endDate=${request.lastDate}&search=${request.name}&page=${request.page}&size=${request.size}&sortDirection=DESC&sortField=consultationStartDatetime`,
      { headers: headers() },
    );
    // return await axios.get('https://dev-api.klinisia.id/admin-hospital/consultation/list-simple?startDate=2021-03-21&endDate=2021-03-22&page=0&size=10000000&sortDirection=DESC&sortField=consultationStartDatetime', { headers: headers() })
  }
  async getAdminPasienListDetail(id) {
    return await axios.get(
      `${API_URL}admin-hospital/consultation/detail/${id}`,
      { headers: headers() },
    );
  }
  async getSummary() {
    return await axios.get(
      `${API_URL}admin-hospital/consultation/summary-question`,
      { headers: headers() },
    );
  }
  async getQueueList() {
    return await axios.get(`${API_URL}admin/consultation/queue`, {
      headers: headers(),
    });
  }
  async getChat(id) {
    return await axios.get(`${API_URL}admin-hospital/chat/history/${id}`, {
      headers: headers(),
    });
  }
  async retry(id) {
    return await axios.post(
      `${API_URL}admin-hospital/chat/re-import/${id}`,
      id,
      { headers: headers() },
    );
  }
  async vidCall(id) {
    return await axios.get(`${API_URL}admin/vcall/get/${id}`, {
      headers: headers(),
    });
  }
}

export default new Pasien();
