<template>
  <v-app class="background">
    <v-container>
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col>
            <v-form ref="form" v-model="valid" lazy-validation>
              <h5 class="mt-3">Tambah Rumah Sakit</h5>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Nama Rumah Sakit</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="params.hospitalName"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Kode Rumah Sakit</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="params.hospitalCode"
                    :rules="rules"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Deskripsi Rumah Sakit</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="params.hospitalDescription"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Type Rumah Sakit</p>
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="params.hptHospitalTypeId"
                    :items="dataType"
                    hide-details
                    item-value="value"
                    @input="selecttype"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Harga Konsultasi Tanpa Janji</p>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :rules="rules"
                    required
                    type="number"
                    outlined
                    v-model="params.consultationFee"
                    class="inputPrice"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Kode Faktur</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="params.hospitalCode2"
                    :rules="rules"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Email</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="params.email"
                    :rules="emailRules"
                    label=""
                    required
                    type="email"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>No Telp</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="params.phone"
                    :rules="rules"
                    required
                    type="number"
                    outlined
                    class="inputPrice"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Negara</p>
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="negara"
                    :items="dataNegara"
                    hide-details
                    item-value="value"
                    @input="selectNeg"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Provinsi</p>
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="provinsi"
                    :items="dataProvince"
                    hide-details
                    item-value="value"
                    @input="selectprov"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Kota</p>
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="kota"
                    :items="dataCity"
                    item-value="value"
                    item-text="text"
                    hide-details
                    @input="selectcity"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Kecamatan</p>
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    item-value="value"
                    item-text="text"
                    v-model="kecamatan"
                    :items="dataDistrict"
                    hide-details
                    @input="selectdistrict"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Kelurahan</p>
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="params.geoSubDistrictId"
                    :items="dataSubDistrict"
                    hide-details
                    item-value="value"
                    item-text="text"
                    @input="selectsubdistrict"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Kode Pos</p>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="params.postalCode"
                    :rules="rules"
                    required
                    type="number"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Alamat</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="params.hospitalAddress"
                    :rules="rules"
                    required
                    type="text"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Zona Waktu</p>
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    :items="timeZone"
                    hide-details
                    item-value="value"
                    item-text="text"
                    v-model="params.timeZoneId"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-4">
                  <p>Jenis API</p>
                </v-col>
                <v-col cols="6" class="">
                  <!-- <v-radio-group
                    v-model="params.sirsIntegrationType"
                    mandatory
                    column
                  >
                    <v-radio label="Quantum" value="Quantum">
                      <template v-slot:label>
                        <p class="mt-6">Dengan API</p>
                        <v-text-field
                          v-model="forsir"
                          :rules="rules"
                          required
                          outlined
                          dense
                          class="ml-9 mt-6"
                        ></v-text-field> </template
                    ></v-radio>
                    <v-radio label="Tanpa API" value="No"></v-radio>
                  </v-radio-group> -->

                  <v-radio-group
                    v-model="params.sirsIntegrationType"
                    mandatory
                    column
                  >
                    <v-radio label="Quantum" value="Quantum">
                      <template v-slot:label>
                        <p class="mt-6">Quantum</p>
                        <v-row>
                          <v-text-field
                            label="appId"
                            v-if="params.sirsIntegrationType === 'Quantum'"
                            v-model="params.sirsIntegrationConfig.appId"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-row>
                        <v-row>
                          <v-text-field
                            label="secretKey"
                            v-if="params.sirsIntegrationType === 'Quantum'"
                            v-model="params.sirsIntegrationConfig.secretKey"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-row>
                        <v-row>
                          <v-text-field
                            label="url"
                            v-if="params.sirsIntegrationType === 'Quantum'"
                            v-model="params.sirsIntegrationConfig.url"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-row>
                      </template>
                    </v-radio>

                    <v-radio label="Ksi" value="Ksi">
                      <template v-slot:label>
                        <p class="mt-6">KSI</p>
                        <v-row>
                          <v-text-field
                            label="appId"
                            v-if="params.sirsIntegrationType === 'Ksi'"
                            v-model="params.sirsIntegrationConfig.appId"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-row>
                        <v-spacer></v-spacer>
                        <v-row>
                          <v-text-field
                            label="secretKey"
                            v-if="params.sirsIntegrationType === 'Ksi'"
                            v-model="params.sirsIntegrationConfig.secretKey"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-row>
                        <v-row>
                          <v-text-field
                            label="url"
                            v-if="params.sirsIntegrationType === 'Ksi'"
                            v-model="params.sirsIntegrationConfig.url"
                            :rules="rules"
                            required
                            outlined
                            dense
                            class="ml-9 mt-6"
                          ></v-text-field>
                        </v-row>
                      </template>
                    </v-radio>
                    <v-radio label="Tanpa API" value="No"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="3" class="mt-2">
                  <p>No Urut</p>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="params.registrationNumber"
                    :rules="rules"
                    required
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row class="mr-3 mt-n7" justify="center">
                <v-col cols="12" md="4" class="">
                  <v-switch
                    v-model="params.withoutAppointmentPaidStatus"
                    dense
                    class="ml-3"
                    ><template v-slot:label>
                      <div>
                        <p class="mt-5 instructionType">
                          Konsultasi Tanpa Janji
                        </p>
                      </div>
                    </template>
                  </v-switch>
                </v-col>

                <v-col cols="12" md="4">
                  <v-switch
                    v-model="params.withAppointmentPaidStatus"
                    dense
                    class="ml-3"
                    ><template v-slot:label>
                      <div>
                        <p class="mt-5 instructionType">
                          Konsultasi Dengan Janji
                        </p>
                      </div>
                    </template>
                  </v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="params.pharmacyPaidStatus"
                    dense
                    class="ml-3"
                    ><template v-slot:label>
                      <div>
                        <p class="mt-5 instructionType">Tebus Resep</p>
                      </div>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
              <v-row class="mt-n5">
                <v-col cols="6">
                  <b-form-group class="text-label">
                    <b-form-file
                      @change="upload($event)"
                      accept="image/*"
                      placeholder="Upload Logo"
                      ref="fileinput"
                    ></b-form-file>
                  </b-form-group>
                </v-col>
              </v-row>


            <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Banner</p>
                </v-col>
              <v-row>
                 <v-col cols="5">
                    <b-form-group class="text-label">
                    <b-form-file
                      accept="image/*"
                      placeholder="Upload Banner"
                      ref="fileinput"
                      v-model="banner"
                    ></b-form-file>
                  </b-form-group>
                 </v-col>
               
                <v-col cols="1"></v-col>
                <v-col cols="2">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-1"
                    fab
                    dark
                    color="indigo"
                    x-small
                    @click="addBanner"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>

              </v-row>
            </v-row>

            <v-row 
             v-show="newBanners"
                v-for="(item, i) in images"
                :key="i"
            >
                <v-col cols="3" class="mt-2">
                </v-col>
              <v-row>

                <v-col cols="5">
                    <b-form-group class="text-label">
                      
                    <v-text-field
                        v-model="item.name"
                        placeholder="url"
                        readonly
                        outlined
                        dense
                      ></v-text-field>
                      <v-img
                        max-height="150"
                        max-width="150"
                        :ref="'image'" 
                        class="mb-3"
                      ></v-img>
                  </b-form-group>
                  
               </v-col>

                <v-col cols="1"></v-col>
                <v-col cols="2">
                  <v-btn
                    style="margin-left: -50px"
                    class="mt-1"
                    fab
                    dark
                    color="error"
                    x-small
                    @click="clearBanner(i)"
                  >
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

            </v-row>

            </v-form>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row>
          <v-col align="right">
            <v-btn class="mr-2" small color="error" dark to="/hospitalList"
              >Kembali</v-btn
            >
            <v-btn class="mr-10" small color="primary" @click="simpan" dark
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
  import serviceHospital from "@/services/hospital_services";
  import servicegeo from "@/services/geo_services";
  import axios from "axios";
  export default {
    components: {
      // Popup
    },
    data: () => ({
      newBanners: false,
      banners: [],
      valueBanners : [],
      banner: null,
      image: "",
      images :[],
      foto: null,
      forsir: "",
      valid: true,
      rules: [(v) => !!v || "Harus Di isi"],
      dataRs: [],
      dataNegara: [],
      dataProvince: [],
      dataCity: [],
      dataDistrict: [],
      dataSubDistrict: [],
      dataType: [],
      geoitem: [],
      negara: "",
      provinsi: "",
      kota: "",
      kecamatan: "",
      kelurahan: "",
      postalcode: "",
      timeZone: [
        {
          text: "WIB",
          value: "WIB",
        },
        {
          text: "WITA",
          value: "WITA",
        },
        {
          text: "WIT",
          value: "WIT",
        },
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || "E-mail must be valid",
      ],
      params: {
        hptHospitalId: "",
        hptHospitalTypeId: "",
        hospitalCode: "",
        hospitalCode2: "",
        sirsIntegrationType: "",
        hospitalName: "",
        registrationNumber: "1",
        phone: "",
        email: "",
        viewOrder: 0,
        mainStatus: true,
        geoSubDistrictId: "",
        postalCode: "",
        hospitalAddress: "",
        hospitalDescription: "",
        sirsIntegrationConfig: {
          appId: "",
          secretKey: "",
          url: "",
        },
        consultationFee: "",
        consultationDiscountPercentage: 0,
        consultationDiscount: 0,
        withAppointmentPaidStatus: true,
        withoutAppointmentPaidStatus: true,
        pharmacyPaidStatus: true,
        timeZoneId: "",
      },
    }),
    mounted() {
      this.gethospital();
      this.getCountry();
      this.getType();
    },
    watch: {},
    computed: {
      years() {
        const year = new Date().getFullYear();
        return Array.from(
          { length: year - 1950 },
          (value, index) => 1951 + index,
        );
      },

    },
    methods: {

 
      clearBanner(item) {
        
        this.images.splice(item, 1);
        this.showImage(this.images)
        this.image = "";
     
      },

       showImage(data) {
       
        for (let i = 0; i < data.length; i++) {
          let reader = new FileReader();
          reader.onload = () => {
              this.$refs.image[i].src = reader.result;
              console.log(i)
            };

            reader.readAsDataURL(this.images[i]);
        }
      },
      addBanner() {
        if (this.banner === null ) {
          this.$toast.error("Banner masih kosong", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
        } else {

          var selectedFiles = this.banner
          this.images.push(selectedFiles);
          this.showImage(this.images)
         
          
          console.log(this.images)
          this.newBanners = true;
          this.banner = null;
        }
      },

      upload(event) {
        console.log("VALUE " + JSON.stringify(event));
        console.log(event.target);
        this.foto = event.target.files[0];
        console.log(this.foto);
      },


      selectNeg() {
        console.log(this.dataNegara);
        console.log(this.negara);
        this.dataProvince = [];
        servicegeo.getProvince(this.negara).then((response) => {
          console.log("ini privince", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataProvince.push({
              value: e.geoProvinceId,
              text: e.provinceName,
            });
          });
        });
      },
      selectprov() {
        console.log(this.dataProvince);
        console.log(this.provinsi);
        this.dataCity = [];
        servicegeo.getCity(this.provinsi).then((response) => {
          console.log("ini city", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataCity.push({
              value: e.geoCityId,
              text: e.cityName,
            });
          });
        });
      },
      selecttype() {
        console.log(this.params.hptHospitalTypeId);
      },
      selectcity() {
        console.log(this.kota);
        this.dataDistrict = [];
        servicegeo.getDistrict(this.kota).then((response) => {
          console.log("ini city", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataDistrict.push({
              value: e.geoDistrictId,
              text: e.districtName,
            });
          });
        });
      },
      selectdistrict() {
        console.log(this.kecamatan);
        this.dataSubDistrict = [];
        servicegeo.getSubDistrict(this.kecamatan).then((response) => {
          console.log("ini city", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataSubDistrict.push({
              value: e.geoSubDistrictId,
              text: e.subDistrictName,
            });
          });
        });
      },
      selectsubdistrict() {
        servicegeo
          .getSubDistrictByID(this.params.geoSubDistrictId)
          .then((response) => {
            console.log(response);
            this.params.postalCode = response.data.payload.postalCode;
          });
      },
      getCountry() {
        servicegeo.getCountry().then((response) => {
          console.log("ini country", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataNegara.push({
              value: e.geoCountryId,
              text: e.countryName,
            });
          });
        });
      },
      getType() {
        serviceHospital.getHospitalTypeAll().then((response) => {
          console.log("ini type", response);
          this.master = response.data.payload.content;
          this.master.map((e) => {
            this.dataType.push({
              value: e.hptHospitalTypeId,
              text: e.hospitalTypeName,
              code: e.hospitalTypeCode,
            });
          });
        });
      },
      gethospital() {
        serviceHospital.getHospital().then((response) => {
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            console.log(response);
            var hospital = response.data.payload.content;
            this.staturs.push(...this.isactive.filter((obj) => obj.status));
            hospital.map((e) => {
              this.dataRs.push({
                id: e.hptHospitalId,
                code: e.hptHospitalCode,
                nama: e.hospitalName,
                typeid: e.hptHospitalTypeId,
                noregis: e.registrationNumber,
                tlp: e.phone,
                email: e.email,
                province: e.provinceName,
                city: e.cityName,
                status: this.staturs.map((e) => e.label),
              });
            });
          }
        });
      },
      simpan() {
        // var data = 'asdsasdsa:aaaa,trial: kkkk'

        // console.log(this.params);
        // console.log(s)
        //  console.log(this.params);
        // console.log(this.forsir.split(",").length);
        // var data = this.forsir.split(",")
        // for(let i = 0 ; i < data.length; i++){
        //   console.log('additionalProp'+ [i+1] +`:${data[i]}`)
        //    this.params.sirsIntegrationConfig['additionalProp'+[i+1]] = data[i]
        // }
        // console.log(this.params);

        if (
          this.params.hospitalName === "" &&
          this.params.hospitalCode === "" &&
          this.params.hptHospitalTypeId === "" &&
          this.params.email === "" &&
          this.params.phone === "" &&
          this.params.geoSubDistrictId === "" &&
          this.params.hospitalAddress === ""
        ) {
          this.$toast.open({
            message: "Harap Isi Semua Field",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else if (
          !this.params.email.includes("@") ||
          !this.params.email.includes(".")
        ) {
          this.$toast.error("Format Email Tidak Sesuai", {
            type: "error",
            position: "top-right",
            duration: 4000,
          });
        } else if (this.params.hospitalName === "") {
          this.$toast.open({
            message: "Harap Isi Nama Rumah Sakit",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else if (this.params.hospitalCode === "") {
          this.$toast.open({
            message: "Harap Isi Kode Rumah Sakit",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else if (this.params.hospitalCode2 === "") {
          this.$toast.open({
            message: "Harap Isi Kode faktur",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else if (this.params.hptHospitalTypeId === "") {
          this.$toast.open({
            message: "Harap Pilih Type Rumah Sakit",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else if (this.params.email === "") {
          this.$toast.open({
            message: "Harap Isi Email Rumah Sakit",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else if (this.params.phone === "") {
          this.$toast.open({
            message: "Harap Isi Nomer Telepon Rumah Sakit",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else if (this.params.geoSubDistrictId === "") {
          this.$toast.open({
            message: "Harap Isi Kelurahan Rumah Sakit",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else if (this.params.hospitalAddress === "") {
          this.$toast.open({
            message: "Harap Isi Alamat Rumah Sakit",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          if (this.params.sirsIntegrationType === "No") {
            delete this.params.sirsIntegrationConfig["appId"];
            delete this.params.sirsIntegrationConfig["secretKey"];
            delete this.params.sirsIntegrationConfig["url"];
            this.params.sirsIntegrationConfig["mrnFormat"] = ".";
            console.log(this.params);
          }
          serviceHospital.addHospital(this.params).then((response) => {
            console.log(response);
            // if (response.data.error !== "") {
            //   this.$toast.open({
            //     message: response.data.error,
            //     position: "top-right",
            //     type: "error",
            //     duration: 6000,
            //   });
            // }
            if (response.data.error !== "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
                message: "Rumah Sakit Berhasil Di tambahkan",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              let hptHospitalId = response.data.payload.hptHospitalId;
              if (this.foto !== null) {
                let formData = new FormData();
                formData.append("profilePicture", this.foto);
                axios
                  .post(
                    `https://dev-api.klinisia.id/admin/storage/file/hospital-logo/${hptHospitalId}`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",

                        Authorization:
                          "Bearer " + window.localStorage.getItem("shortToken"),
                      },
                    },
                  )
                  .then((response) => {
                    console.log("foto sukses");
                    console.log(response);
                    // window.location.reload()
                    this.$router.push("/hospitalList");
                  })
                  .catch((err) => {
                    console.log("foto error", err);
                    // this.$toast.error(err, {
                    //   type: "error",
                    //   position: "top-right",
                    //   duration: 5000,
                    // });
                    // console.log(this.foto);
                  });
              } else {
                this.$router.push("/hospitalList");
              }
            }
          });
        }
      },
    },
  };
</script>
<style lang="scss">
  @import "../../scss/dashboard.scss";
  .titleContent {
    font-weight: 500;
    line-height: 1rem !important;
  }
  .unstyled,
  .v-input
    > .v-input-control
    > .v-input-slot
    > .v-text-field__slot
    > input
    > ::-webkit-inner-spin-button,
  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .inputPrice input[type="number"] {
    -moz-appearance: textfield;
  }
  .inputPrice input::-webkit-outer-spin-button,
  .inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
  .background {
    background-color: #edf4fb;
  }
  .cardsearch {
    background-color: #ffffff;
    border-radius: 30px;
    width: 99%;
    margin-left: 2px;
  }
  .headerComponent {
    margin-left: 250px;
  }
  .datapasien {
    margin-left: 250px;
    margin-right: 120px;
  }
  .header {
    margin-left: 250px;
    /* margin-right: 250px; */
  }
  .phone {
    text-decoration: none;
  }
  .poppuphead {
    text-align: center;
  }
  .v-card__title {
    font-size: 0.9rem !important;
    line-height: 1rem !important;
  }
  .instructionType {
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
  }
  .search {
    margin-top: 15px;
    margin-left: 10px;
  }
  .detail {
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #2b92e4;
  }
  .nama {
    font-family: Nunito;
    font-size: 22px;
    text-align: left;
  }
  .icd-search {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #2b92e4;
  }
  .icd-text {
    font-family: Nunito;
    font-size: 16px;
    font-weight: bold;
  }
</style>
